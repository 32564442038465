import { Action, createReducer, on } from '@ngrx/store';
import { IshtarDocument } from '../../domain/models/ishtar-document.model';

import {
  getActiveDocument,
  getActiveDocumentResolved,
  getDocumentsResolved,
  patchActiveDocumentResolved,
  removeDocumentResolved,
  setActiveDocument,
} from './document.actions';
import {AppState} from "../../../shared/store/app.reducer";

export const featureSlice = 'document';

export interface State {
  activeDoc?: IshtarDocument;
  documents: IshtarDocument[];
  pagingCookie: string;
  totalRecordCount: number;
}

const defaultState: State = {
  activeDoc: undefined,
  documents: [],
  pagingCookie: '',
  totalRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(getActiveDocumentResolved, (state, { documentInfo }) => ({
    ...state,
    activeDoc: documentInfo,
  })),
  on(setActiveDocument, (state, { documentId }) => ({
    ...state,
    activeDoc: state.documents.find(
      (doc) => doc.ishtarDocsDocumentId == documentId
    ),
  })),
  // on(getDocumentsResolved, (state, { documents }) => ({
  //   ...state,
  //   documents,
  // })),
  on(
    getDocumentsResolved,
    (state, { result, pagingCookie, totalRecordCount, resetPaging }) => ({
      ...state,
      documents: resetPaging ? [...result] : [...state.documents, ...result],
      pagingCookie,
      totalRecordCount,
    })
  ),
  on(removeDocumentResolved, (state, { documentIds }) => ({
    ...state,
    documents: state.documents.filter((doc) =>
      documentIds.includes(doc.ishtarDocsDocumentId)
    ),
  })),
  on(patchActiveDocumentResolved, (state, { document }) => ({
    ...state,
    activeDoc: document,
    documents: state.documents.map((doc) =>
      doc.ishtarDocsDocumentId == document.ishtarDocsDocumentId ? document : doc
    ),
  }))
);

export const documentState = (state: AppState) => state.coreFeature.document;
