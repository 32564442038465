import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {blockSectionSelectors} from './blockSection.selectors';
import {AppState} from '../core.feature';

import {
  getBlockSections,
  getBlockSectionTranslations,
  getDocBlockTags,
  getDocumentBlocks,
  getNewDocBlockSections,
  newBlockSection,
  patchBlockHeaderTranslations,
  patchBlockSection,
  patchBlockTextTranslations,
  patchDocBlockTags,
  patchDocumentBlocks,
} from './blockSection.actions';
import {Subject} from 'rxjs';
import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from '../../domain/models/ishtar-translations.model';
import {IshtarDocumentBlock} from '../../domain/models/ishtar-document-block.model';
import {IshtarDocumentBlockTag} from '../../domain/models/tags/ishtar-document-block-tag.model';
import {IshtarBlockSection} from '../../domain/models/ishtar-block-section.model';
import {LoaderFacade} from "../../../shared/store/shared-feature/loader/loader.facade";
import {vartranslations$} from "../../api/data/data.observables";

@Injectable({
  providedIn: 'root',
})
export class BlockSectionFacade {
  blockSections$ = this._store.pipe(
    select(blockSectionSelectors.getBlockSections)
  );

  documentBlocks$ = this._store.pipe(
    select(blockSectionSelectors.getDocumentBlocks)
  );

  activeTags$ = this._store.pipe(select(blockSectionSelectors.getActiveTags));

  pagingCookie$ = this._store.pipe(
    select(blockSectionSelectors.getPagingCookie)
  );

  totalRecordCount$ = this._store.pipe(
    select(blockSectionSelectors.getTotalRecordCount)
  );

  constructor(private _store: Store<AppState>, private loaderFacade: LoaderFacade) {}


  // getBlockSections(callback?: () => void) {
  //   this._store.dispatch(getBlockSections({ callback }));
  // }
  getBlockSections$(newPageSize: number, sortedColumn: string, sortDirection: string, filters: [string,any][], newPagingCookie?: string) {
    const id = this.loaderFacade.startLoading(vartranslations$.value.loadingBlockSections);
    const subject = new Subject<void>();
    this._store.dispatch(getBlockSections({
        newPageSize, sortedColumn, sortDirection, filters, newPagingCookie,
        callback: () => {
          this.loaderFacade.stopLoading(id);
          subject.next();
          subject.complete();
        },
      })
    );
     this.getBlockSectionTranslations();
    return subject.asObservable();
  }

  newBlockSection(callback?: () => void) {
    this._store.dispatch(newBlockSection({callback}));
  }

  getDocumentBlocks(callback?: () => void) {
    this._store.dispatch(getDocumentBlocks({callback}));
  }

  getBlockSectionTranslations(callback?: () => void) {
    this._store.dispatch(getBlockSectionTranslations({callback}));
  }

  getDocumentBlocks$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getDocumentBlocks({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  patchBlockSection(BlockSection: IshtarBlockSection, callback?: () => void) {
    this._store.dispatch(
      patchBlockSection({
        BlockSection,
        callback,
      })
    );
  }

  patchBlockHeaderTranslations(
    translations: IshtarBlockSectionHeaderTranslation[],
    callback?: () => void
  ) {
    this._store.dispatch(
      patchBlockHeaderTranslations({
        blockHeaderTranslations: translations,
        callback: callback,
      })
    );
  }

  patchBlockTextTranslations(
    translations: IshtarBlockSectionTextTranslation[],
    callback?: () => void
  ) {
    this._store.dispatch(
      patchBlockTextTranslations({
        blockTextTranslations: translations,
        callback: callback,
      })
    );
  }

  getNewDocBlocks(documentId: string, callback?: () => void) {
    this._store.dispatch(getNewDocBlockSections({documentId, callback}));
  }

  getDocumentBlockTags(documentBlockId: string, callback?: () => void) {
    this._store.dispatch(getDocBlockTags({documentBlockId, callback}));
  }

  patchDocumentBlockTags(
    documentBlockTags: IshtarDocumentBlockTag[],
    callback?: () => void
  ) {
    this._store.dispatch(patchDocBlockTags({documentBlockTags, callback}));
  }

  patchDocumentBlocks(
    documentBlocks: IshtarDocumentBlock[],
    callback?: () => void
  ) {
    this._store.dispatch(patchDocumentBlocks({documentBlocks, callback}));
  }
}
