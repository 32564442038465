import { IshtarTranslation } from '../ishtar-translations.model';

export class IshtarCompanyDetail {
  ishtarDocsCompanyDetailId!: string;
  name!: string;
  street?: string;
  houseNumber?: number;
  zipcode?: number;
  phoneNr?: string;
  enterpriceNr?: string;
  website?: string;
  mail?: string;
  country?: { id: string; name: string };

constructor(obj:Partial<IshtarCompanyDetail>){
Object.assign(this,obj);
}
}

export class IshtarCompanyDetailExpanded {
  ishtarDocsCompanyDetailId!: string;
  name!: string;
  street?: string;
  houseNumber?: string;
  zipcode?: string;
  phoneNr?: string;
  enterpriceNr?: string;
  website?: string;
  mail?: string;
  country?: { id: string; name: string };
  cityTranslations: IshtarCityTranslation[] = [];

  constructor(obj: Partial<IshtarCompanyDetailExpanded | IshtarCompanyDetail>) {
    Object.assign(this, obj);
  }
}

export class IshtarCityTranslation extends IshtarTranslation {
  ishtarDocsCityTranslationId!: string;

  constructor(obj: Partial<IshtarCityTranslation>) {
    super();
    delete (obj as any).name;
    Object.assign(this, obj);
  }
}
