import { IshtarTemplateService } from '../../api/services/ishtarTemplate.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';

import {
  getActiveDocument,
  getActiveDocumentResolved,
  getDocuments,
  getDocumentsResolved,
  patchActiveDocument,
  patchActiveDocumentResolved,
  removeDocument,
  removeDocumentResolved,
} from './document.actions';

@Injectable({ providedIn: 'root' })
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private ishtarTemplate: IshtarTemplateService
  ) {}

  getActiveDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(getActiveDocument),
      switchMap(({ documentId, callback }) =>
        this.ishtarTemplate.getDocument(documentId).pipe(
          switchMap((data) =>
            of(getActiveDocumentResolved({ documentInfo: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  // getDocuments = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getDocuments),
  //     switchMap(({ callback }) =>
  //       this.ishtarTemplate.getDocuments().pipe(
  //         switchMap((data) => of(getDocumentsResolved({ documents: data }))),
  //         tap(() => (callback ? callback() : undefined))
  //       )
  //     )
  //   )
  // );

  getDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocuments),
      mergeMap(
        ({
           newPageSize,
           sortedColumn,
           sortDirection,
           filters,
           newPagingCookie,
           callback,
         }) =>
          this.ishtarTemplate
            .getDocuments(
              newPageSize,
              sortedColumn,
              sortDirection,
              filters,
              newPagingCookie
            )
            .pipe(
              switchMap(({ result, pagingCookie, totalRecordCount }) =>
                of(
                  getDocumentsResolved({
                    result,
                    pagingCookie,
                    totalRecordCount,
                    resetPaging: !newPagingCookie,
                  })
                )
              ),
              tap(() => (callback ? callback() : undefined)),
              catchError((e) => [])
            )
      )
    )
  );

  removeDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDocument),
      switchMap(({ documentId, callback }) =>
        this.ishtarTemplate.removeDocument(documentId).pipe(
          switchMap((data) =>
            of(removeDocumentResolved({ documentIds: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(patchActiveDocument),
      switchMap(({ document, callback }) =>
        this.ishtarTemplate.patchDocument(document).pipe(
          switchMap((data) =>
            of(patchActiveDocumentResolved({ document: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );
}
