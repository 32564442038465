import {bootstrapApplication} from '@angular/platform-browser';
import {provideRouter} from '@angular/router';
import {EffectsModule, provideEffects} from '@ngrx/effects';
import {provideStoreDevtools, StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppComponent, appRoutes} from './app/app.component';
import {provideStore, StoreModule} from '@ngrx/store';
import {enableProdMode, importProvidersFrom, inject} from '@angular/core';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {AppInitializer} from './app/core/services/application.initializer';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_INSTANCE,
  ISHTAR365_URL,
  MsalModule,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_FUNCTIONS_SCOPE, microsoftAuthenticationInterceptor,
} from 'processdelight-angular-components';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {provide: ISHTAR365_CLIENTID, useValue: environment.clientId},
    {provide: ISHTAR365_INSTANCE, useValue: environment.instance},
    {provide: ISHTAR365_URL, useValue: environment.ishtar365},
    {provide: ISHTAR365_APP_IDENTIFIER, useValue: environment.appIdentifier},
    {provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions},
    {provide: ISHTAR365_FUNCTIONS_SCOPE, useValue: environment.ishtarFunctionsScope},
    {provide: ISHTAR365_LAST_ROUTE_KEY, useValue: 'ISHTARTEMPLATE_LAST_ROUTE_KEY'},
    {provide: ISHTAR365_INITIAL_ROUTE, useValue: ['/documents']},
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(AppInitializer);
        return () => service.initialize();
      },
    },
    provideRouter(appRoutes),
    provideStore(),
    provideStoreDevtools({}),
    provideEffects([]),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({logOnly: environment.production}),
      MsalModule,
    ),
    provideHttpClient(withInterceptors([microsoftAuthenticationInterceptor('')])),
    provideAnimations(),
  ],
})
;
