import { createSelector } from '@ngrx/store';
import { IshtarDocumentBlockTagExtended } from '../../domain/models/tags/ishtar-document-block-tag.model';
import {
  IshtarTag,
  IshtarTagExtended,
} from '../../domain/models/tags/ishtar-tag.model';
import { AppState } from '../core.feature';

const tagState = (state: AppState) => state.coreFeature.tags;

const getTags = createSelector(tagState, (state) => state.tags);

const getTagTypes = createSelector(tagState, (state) => state.tagTypes);

const getDocumentBlockTag = createSelector(
  tagState,
  (state) => state.documentBlockTags
);

const getDocumentBlockTagExtended = createSelector(
  getDocumentBlockTag,
  getTags,
  getTagTypes,
  (blocktags, tags, tagtypes) => {
    return blocktags.map((bt) => {
      const tag = tags.find(
        (t) => t.ishtarDocsTagId == bt?.tag?.ishtarDocsTagId,
        bt.tag
      );
      return new IshtarDocumentBlockTagExtended({
        ...bt,
        tag: new IshtarTagExtended({
          ...tag,
          tagType: tagtypes.find(
            (tt) => tt.ishtarDocsTagTypeId == tag?.tagType.id
          ),
        }),
      });
    });
  }
);

export const tagSelectors = {
  getTags,
  getTagTypes,
  getDocumentBlockTag,
  getDocumentBlockTagExtended,
};
