import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {documentSelectors} from './document.selectors';
import {AppState} from '../core.feature';
import {BehaviorSubject, filter, Subject} from 'rxjs';
import {
  getActiveDocument,
  getDocuments,
  patchActiveDocument,
  setActiveDocument,
} from './document.actions';
import {IshtarDocument} from '../../domain/models/ishtar-document.model';
import {LoaderFacade} from "../../../shared/store/shared-feature/loader/loader.facade";
import {documentState} from "./document.reducer";
import {map} from "rxjs/operators";
import {vartranslations$} from "../../api/data/data.observables";

@Injectable({
  providedIn: 'root',
})
export class DocumentFacade {


  documentsSubject = new BehaviorSubject<IshtarDocument[]>([]);

  getDocumentByIdFunc$ = this._store.pipe(
    select(documentSelectors.getDocumentById)
  );

  documents$ = this._store.pipe(
    select(documentSelectors.getDocuments)
  );
  documents = this.documentsSubject.getValue();

  activeDocument$ = this._store.pipe(
    select(documentSelectors.getActiveDocument)
  );
  pagingCookie$ = this._store.pipe(
    select(documentSelectors.getPagingCookie)
  );
  totalRecordCount$ = this._store.pipe(
    select(documentSelectors.getTotalRecordCount)
  );


  constructor(private _store: Store<AppState>, private loaderFacade: LoaderFacade) {
    _store
      .pipe(
        select(documentSelectors.getDocuments),
        filter((doc) => doc.length != 0)
      )
      .subscribe(this.documentsSubject);
  }

  getDocument(documentId: string, callback?: () => void) {
    this._store.dispatch(getActiveDocument({documentId, callback}));
  }

  // getDocuments(callback?: () => void) {
  //   this._store.dispatch(getDocuments({ callback }));
  // }

  getDocuments$(newPageSize: number, sortedColumn: string, sortDirection: string, filters: [string,any][], newPagingCookie?: string) {
    const id = this.loaderFacade.startLoading(vartranslations$.value.loadingDocuments);
    const subject = new Subject<void>();
    this._store.dispatch(getDocuments({
        newPageSize, sortedColumn, sortDirection, filters, newPagingCookie,
        callback: () => {
          this.loaderFacade.stopLoading(id);
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getActiveDocument(documentId: string, callback?: () => void) {
    this._store.dispatch(getActiveDocument({documentId, callback}));
  }

  setActiveDocument(documentId: string, callback?: () => void) {
    this._store.dispatch(setActiveDocument({documentId, callback}));
  }

  patchActiveDocument(document: IshtarDocument, callback?: () => void) {
    this._store.dispatch(patchActiveDocument({document, callback}));
  }
}
