import { Component, OnInit } from '@angular/core';
import { LoaderFacade } from '../../store/shared-feature/loader/loader.facade';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  show = false;
  loaderText$ = this.loaderFacade.getLoaderText$;
  isLoading$ = this.loaderFacade.isLoading$;

  constructor(private loaderFacade: LoaderFacade) {}
}
