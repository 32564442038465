import { createAction, props } from '@ngrx/store';
import {
  IshtarDocumentBlockTag,
  IshtarDocumentBlockTagExtended,
} from '../../domain/models/tags/ishtar-document-block-tag.model';
import { IshtarTagType } from '../../domain/models/tags/ishtar-tag-type.model';
import { IshtarTag } from '../../domain/models/tags/ishtar-tag.model';

export const getTags = createAction(
  '[Tags] Get the tags for all blocksections',
  props<{ callback?: () => void }>()
);
export const getTagsResolved = createAction(
  '[Tags] Get the tags for all blocksections Resolved',
  props<{
    response: IshtarTag[];
  }>()
);

export const getTagTypes = createAction(
  '[Tags] Get all tagTypes',
  props<{ callback?: () => void }>()
);
export const getTagTypesResolved = createAction(
  '[Tags] Get all tagTypes Resolved',
  props<{ response: IshtarTagType[] }>()
);

export const getAllDocBlockTags = createAction(
  '[Tags] Get all Documentblock Tags',
  props<{ callback?: () => void }>()
);
export const getAllDocBlockTagsResolved = createAction(
  '[Tags] Get all Documentblock Tags Resolved',
  props<{
    response: IshtarDocumentBlockTagExtended[];
  }>()
);

export const createTagType = createAction(
  '[Tags] Create a new tagType',
  props<{ tagType: IshtarTagType; callback?: () => void }>()
);
export const createTagTypeResolved = createAction(
  '[Tags] Create a new tagType Resolved',
  props<{ response: IshtarTagType[] }>()
);

export const patchTagType = createAction(
  '[Tags] Patch a tagType',
  props<{ tagType: IshtarTagType; callback?: () => void }>()
);
export const patchTagTypeResolved = createAction(
  '[Tags] Patch a tagType Resolved',
  props<{ response: IshtarTagType[] }>()
);
