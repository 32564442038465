import {BehaviorSubject} from "rxjs";
import {UserLicenseInfo} from "../../domain/models/user/ishtar-user-license-info";

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const varlanguages$ = new BehaviorSubject<string[] | undefined>(
  undefined
);
export const vartranslations$ = new BehaviorSubject<any>(
  {}
);
