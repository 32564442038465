import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from './ishtar-translations.model';

export class IshtarBlockSection {
  ishtarDocsBlockSectionId!: string;
  shortname!: string;
  headerLvl!: number;
  default!: boolean;
  parentItem?: { id: string; name: string };
  createdBy!: { id: string; name: string };
  createdOn!: string;
  modifiedBy?: { id: string; name: string };
  modifiedOn?: string;
  headerTranslations?: IshtarBlockSectionHeaderTranslation[];
  textTranslations?: IshtarBlockSectionTextTranslation[];

  constructor(obj: Partial<IshtarBlockSection>) {
    Object.assign(this, obj);
  }
}

export class NewBlockSectionResponse {
  blockSections: IshtarBlockSection[] = [];
  headerTranslations: IshtarBlockSectionHeaderTranslation[] = [];
  textTranslations: IshtarBlockSectionTextTranslation[] = [];

  constructor(obj: Partial<NewBlockSectionResponse>) {
    Object.assign(this, obj);
  }
}
