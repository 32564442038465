import { Action, createReducer, on } from '@ngrx/store';
import { toggleMenu } from './side-bar.actions';

export const featureSlice = 'sidebar';

export interface State {
  menuExtended: boolean;
}

const defaultState: State = {
  menuExtended: false,
};

export function Reducer(state: State | undefined, action: Action) {
  return sideBarReducer(state, action);
}

export const initialState: State = defaultState;
export const sideBarReducer = createReducer(
  initialState,
  on(toggleMenu, (state) => ({ ...state, menuExtended: !state.menuExtended }))
);
