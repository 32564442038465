<link href="https://cdn.quilljs.com/1.0.0/quill.snow.css" rel="stylesheet"/>
<div class="dialogContainer">

  <h1 mat-dialog-title>{{translations.currentTextBlock}}: {{activeBlockSection?.shortname}}</h1>
  <mat-icon class="closeButtonIcon" (click)="onCloseTextBlockDialog()">close</mat-icon>


  <mat-dialog-content class="mat-typography">


    <div class="maineditorarea">
      <div class="editorcontainer mb-1">
        <div class="richeditor">
          <!--        ---BEGIN HEADER--- -->
          <mat-toolbar>
            <h3>{{translations.header}}</h3>

            <div class="headerSettingsForm">

              <mat-form-field class="toolbarInput">
                <mat-label>{{translations.editName}}</mat-label>
                <input matInput placeholder="{{translations.name}}" [(ngModel)]="headerNameEditor"/>


              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{translations.parentItem}}</mat-label>
                <mat-select [(value)]="activeParentItem">
                  <mat-option>{{translations.none}}</mat-option>
                  <mat-option [value]="parentItem.id" *ngFor="let parentItem of parentItemDropdownOptions">
                    {{ parentItem.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="toolbarend">

              <div *ngIf="warningText" class="bg-danger rounded">
                <p>{{ warningText }}</p>
              </div>

              <mat-button-toggle-group
                name="fontStyle"
                aria-label="Font Style"
                class="languageSelector"
                [value]="currentHeaderLanguage.id"
              >
                <mat-button-toggle
                  *ngFor="let lang of supportedLanguages"
                  [value]="lang.id"
                  (change)="onHeaderLanguageChange(lang.id)"
                >
                  {{ lang.viewValue }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

          </mat-toolbar>

          <div class="">
            <quill-editor
              #headereditor
              class="quilleditor"
              [(ngModel)]="headerRichEditor">
            </quill-editor>
          </div>

          <!--        ---END HEADER---     -->

          <!--        ---BEGIN TEXT---     -->

          <app-text-editor
            #editor
            class="textAreaSettings"
            [blockSection]="activeBlockSection"
            [supportedLanguages]="supportedLanguages"
            (languageChange)="tageditor.loadBlots()"
            (editorCreated)="tageditor.initSelectionChanged($event)"
          >
          </app-text-editor>
        </div>

        <!--        ---END TEXT---      -->

        <app-tag-editor
          #tageditor
          class="richeditor"
          [textEditor]="editor"
        ></app-tag-editor>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary"
            [mat-dialog-close]="true"
            cdkFocusInitial
            [disabled]="!headerNameEditor"
            (click)="onSaveTextBlock()"
    >
      <mat-icon>save</mat-icon>
      {{translations.save}}
    </button>
  </mat-dialog-actions>
</div>
