import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, of, tap } from 'rxjs';
import { IshtarTemplateService } from '../../api/services/ishtarTemplate.service';
import {
  createTagType,
  createTagTypeResolved,
  getAllDocBlockTags,
  getAllDocBlockTagsResolved,
  getTags,
  getTagsResolved,
  getTagTypes,
  getTagTypesResolved,
  patchTagType,
  patchTagTypeResolved,
} from './tags.actions';

@Injectable({ providedIn: 'root' })
export class TagEffects {
  constructor(
    private actions$: Actions,
    private ishtarTemplate: IshtarTemplateService
  ) {}

  getTags = createEffect(() =>
    this.actions$.pipe(
      ofType(getTags),
      switchMap(({ callback }) =>
        this.ishtarTemplate.getTags().pipe(
          switchMap((data) => of(getTagsResolved({ response: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getTagTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(getTagTypes),
      switchMap(({ callback }) =>
        this.ishtarTemplate.getTagTypes().pipe(
          switchMap((data) => of(getTagTypesResolved({ response: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getAllDocumentBlockTags = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllDocBlockTags),
      switchMap(({ callback }) =>
        this.ishtarTemplate.getDocumentBlockTags().pipe(
          switchMap((data) =>
            of(getAllDocBlockTagsResolved({ response: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  createTagType = createEffect(() =>
    this.actions$.pipe(
      ofType(createTagType),
      switchMap(({ tagType, callback }) =>
        this.ishtarTemplate.createTagType(tagType).pipe(
          switchMap((data) => of(createTagTypeResolved({ response: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchTagType = createEffect(() =>
    this.actions$.pipe(
      ofType(patchTagType),
      switchMap(({ tagType, callback }) =>
        this.ishtarTemplate.patchTagType(tagType).pipe(
          switchMap((data) => of(patchTagTypeResolved({ response: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );
}
