

<div class="TEXTBLOCKS h-100">

<div class="textblock-dashboard" *ngIf="pagedTextBlocks$ | async as blockSections">
  <processdelight-dashboard
    [data]="blockSections"
    [possibleColumns]="getPossibleColumns()"
    addButtonLabel="{{translations.add}}"
    [showExpandedDetails]="false"
    (addButtonClicked)="newBlockSection()"
    [showAddButton]="true"
    (filterChange)="filterChanged($event)"
    [showFilterControl]="true"
    (pageChange)="pageChanged($event)"
    (sortChange)="sortChanged($event)"
    [maxSizeItems]="totalRecordCount"
    [pageSize]="pageSize"
    [selectedColumns]="getSelectedColumns()"
    (columnChange)="columnChanged($event)"
    [sortColumn]="sortedColumn"
    [sortDirection]="sortDirection"
    (rowExpanded)="openDialog($event)"
  ></processdelight-dashboard>
</div>
</div>
