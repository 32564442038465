import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {TextEditorComponent} from "../../components/text-editor/text-editor.component";
import {QuillEditorComponent} from "ngx-quill";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatOptionModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {CoreModule} from "../../../core/core.module";
import {TagEditorComponent} from "../../components/tag-editor/tag-editor.component";
import {IshtarBlockSection} from "../../../core/domain/models/ishtar-block-section.model";
import {BlockSectionFacade} from "../../../core/store/blockSection/blockSection.facade";
import {IshtarBlockSectionHeaderTranslation} from "../../../core/domain/models/ishtar-translations.model";
import {Language, Languages} from "../../../language.component";
import {filter, first, Subject, takeUntil} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {vartranslations$} from "../../../core/api/data/data.observables";

@Component(
  {
    standalone: true,
    selector: 'app-text-block-dialog',
    templateUrl: './text-block-dialog.component.html',
    styleUrls: ['./text-block-dialog.component.scss'],
    imports: [
      TextEditorComponent,
      QuillEditorComponent,
      MatButtonToggleModule,
      MatOptionModule,
      MatInputModule,
      CoreModule,
      TagEditorComponent,
      MatDialogModule
    ]
  }
)
export class TextBlockDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('headereditor') headerEditor!: QuillEditorComponent;
  @ViewChild('editor') editor!: TextEditorComponent;
  @ViewChild('tageditor') tageditor!: TagEditorComponent;
  //translations for the user interface
  translations = vartranslations$.value;

  activeBlockSection?: IshtarBlockSection;

  supportedLanguages: Language[] = Languages.getLanguages();
  currentHeaderLanguage = Languages.getLanguages()[0];

  // warningText in header balk
  warningText = '';

  parentItemDropdownOptions: { id: string; name: string }[] = [];
  activeParentItem: { id: string; name: string } = {id: '', name: ''};

//header part
  headerNameEditor = '';
  parentNameEditor = '';
  headerRichEditor = '';
  // contains the header for each language when initialized in ngOnInit
  headerLocalSaves: IshtarBlockSectionHeaderTranslation[] = [];

//text part -> text-editor

  destroy$ = new Subject<void>();


  constructor(private blockSectionFacade: BlockSectionFacade,
              @Inject(MAT_DIALOG_DATA) public data: { blockSection: IshtarBlockSection },
              public textBlockDialogRef: MatDialogRef<TextBlockDialogComponent>) {
  }

  ngOnInit(): void {
    this.activeBlockSection = this.data.blockSection!;
    this.activeBlockSection.parentItem
      ? (this.activeParentItem = this.activeBlockSection.parentItem) : '';

//header part

    this.headerNameEditor = this.activeBlockSection.shortname;
    this.parentNameEditor = this.activeBlockSection.parentItem?.name || '';
    this.headerLocalSaves = this.activeBlockSection.headerTranslations?.map((header: IshtarBlockSectionHeaderTranslation) =>
      new IshtarBlockSectionHeaderTranslation({...header})) || [];

    this.loadHeaderTextInEditor();
    this.parentItemDropdownOptionsInitialization();

//text part -> text-editor

  }

  ngAfterViewInit() {
     console.log('ngAfterViewInit Dialog')

    //give the editor some time to load the blots
    setTimeout(() => {
      this.tageditor.loadBlots();
    }, 100);

  }


//Header part
  onHeaderLanguageChange(id: string) {
    this.saveHeaderRichEditorToLocal();
    this.currentHeaderLanguage = Languages.getLanguages().find((lang) => lang.id === id) || this.supportedLanguages[0];
    //reload the text in the editor
    this.loadHeaderTextInEditor();
  }

  saveHeader() {
    if (this.activeBlockSection) {
      const parentItem = this.parentItemDropdownOptions.find(
        (t) => t.id == this.activeParentItem.id);

      if (this.headerNameEditor !== this.activeBlockSection.shortname) {
        this.blockSectionFacade.patchBlockSection(
          new IshtarBlockSection({
            ishtarDocsBlockSectionId: this.activeBlockSection.ishtarDocsBlockSectionId,
            shortname: this.headerNameEditor
          })
        );
      }
      this.saveparentItem(parentItem);
      this.saveHeaderRichEditorToLocal();
      this.blockSectionFacade.patchBlockHeaderTranslations(this.headerLocalSaves);
    }
  }

  private loadHeaderTextInEditor() {
    this.headerRichEditor = this.headerLocalSaves.find((header: IshtarBlockSectionHeaderTranslation) =>
      header.language.id == this.currentHeaderLanguage.id)?.translation || 'No translation found';
  }

  private saveHeaderRichEditorToLocal() {
    this.headerLocalSaves = this.headerLocalSaves.map((localsave) =>
      localsave.language.id == this.currentHeaderLanguage.id
        ? new IshtarBlockSectionHeaderTranslation({
          ...localsave,
          translation: this.headerRichEditor,
        })
        : localsave
    );
  }

  private parentItemDropdownOptionsInitialization() {
    this.blockSectionFacade.blockSections$
      .pipe(
        filter((bs) => bs && bs.length > 0),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe((bs) => {
        bs
          .filter((bs) => bs.ishtarDocsBlockSectionId !== this.activeBlockSection?.ishtarDocsBlockSectionId)
          .forEach((bs) =>
            this.parentItemDropdownOptions.push({
              id: bs.ishtarDocsBlockSectionId,
              name: bs.shortname,
            })
          );
      });
  }

  private saveparentItem(parentItem: { id: string; name: string } | undefined) {
    if (parentItem) {
      this.activeBlockSection = new IshtarBlockSection({
        ...this.activeBlockSection,
        parentItem: {
          id: parentItem?.id || '',
          name: parentItem?.name || '',
        },
      })
    } else {
      this.activeBlockSection = new IshtarBlockSection({
        ...this.activeBlockSection,
        parentItem: undefined
      })
    }
  }

//Text part -> text-editor

//Dialog part
  onCloseTextBlockDialog() {
    this.textBlockDialogRef.close();
  }

  onSaveTextBlock() {
    this.saveHeader();
    this.editor.saveText();
  }

}
