import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCoreFeature from './store/core.feature';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QuillModule } from 'ngx-quill';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MatSnackBarModule} from "@angular/material/snack-bar";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    DragDropModule,
    QuillModule.forRoot({}),
    MatButtonModule,
    AngularEditorModule,
    HttpClientModule,
    FormsModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    DragDropModule,
    QuillModule,
    MatButtonModule,
    AngularEditorModule,
    HttpClientModule,
    FormsModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    QuillModule,
    NgxMatSelectSearchModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class CoreModule {}
