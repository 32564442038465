import { Action, createReducer, on } from '@ngrx/store';
import { Observable } from 'rxjs';
import { startLoading, stopLoading } from './loader.actions';

export const featureSlice = 'loader';

export interface LoaderAction {
  id: string;
  text: string;
  action?: Observable<any>;
}

export interface State {
  actions: LoaderAction[];
}

const defaultState: State = {
  actions: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return loaderReducer(state, action);
}

export const initialState: State = defaultState;
export const loaderReducer = createReducer(
  initialState,
  on(startLoading, (state, { id, text, action }) => ({
    ...state,
    actions: [... state.actions, { id, text, action }],
  })),
  on(stopLoading, (state, { id }) => ({
    ...state,
    actions: state.actions.filter((a) => a.id != id),
  }))
);
