<div class="textEditor">
  <div class="editorcontainer">
    <mat-toolbar>
      <h3>{{translations.text}}</h3>
      <div class="textSettings">
        <div class="tagButtons"></div>
      </div>
      <div class="toolbarend">
        <mat-button-toggle-group
          name="fontStyle"
          aria-label="Font Style"
          class="languageSelector"
          [value]="currentTextLanguage.id"
        >
          <mat-button-toggle
            *ngFor="let lang of supportedLanguages"
            [value]="lang.id"
            (change)="onTextLanguageChange(lang.id)"
          >
            {{ lang.viewValue }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-toolbar>
    <div class="richeditor">
      <quill-editor
        #editor
        class="textquilleditor"
        [modules]="quillModules"
        [(ngModel)]="textRichEditor"
        (onContentChanged)="contentChanged($event)"
      >
      </quill-editor>
    </div>
  </div>
</div>
