import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IshtarTagType } from '../../domain/models/tags/ishtar-tag-type.model';
import { AppState } from '../core.feature';
import {
  createTagType,
  getAllDocBlockTags,
  getTags,
  getTagTypes,
  patchTagType,
} from './tags.actions';
import { tagSelectors } from './tags.selectors';

@Injectable({
  providedIn: 'root',
})
export class TagFacade {
  tags$ = this._store.pipe(select(tagSelectors.getTags));
  tagTypes$ = this._store.pipe(select(tagSelectors.getTagTypes));
  documentBlockTags$ = this._store.pipe(
    select(tagSelectors.getDocumentBlockTag)
  );

  documentBlockTagsExtended$ = this._store.pipe(
    select(tagSelectors.getDocumentBlockTagExtended)
  );

  constructor(private _store: Store<AppState>) {}

  getTags(callback?: () => void) {
    this._store.dispatch(getTags({ callback }));
  }

  getTagTypes(callback?: () => void) {
    this._store.dispatch(getTagTypes({ callback }));
  }

  getAllDocumentBlockTags(callback?: () => void) {
    this._store.dispatch(getAllDocBlockTags({ callback }));
  }

  createTagType(tagType: IshtarTagType, callback?: () => void) {
    this._store.dispatch(createTagType({ tagType, callback }));
  }

  patchTagType(tagType: IshtarTagType, callback?: () => void) {
    this._store.dispatch(patchTagType({ tagType, callback }));
  }
}
