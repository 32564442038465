import { createAction, props } from '@ngrx/store';
import { IshtarDocument } from '../../domain/models/ishtar-document.model';

export const getActiveDocument = createAction(
  '[Document] Get a specific document and set as activeDocument',
  props<{ documentId: string; callback?: () => void }>()
);
export const getActiveDocumentResolved = createAction(
  '[Document] Get a specific documents and set as activeDocument resolved',
  props<{ documentInfo: IshtarDocument }>()
);

export const setActiveDocument = createAction(
  '[Document] Set a document as ActiveDocument',
  props<{ documentId: string; callback?: () => void }>()
);

// export const getDocuments = createAction(
//   '[Document] Get all documents',
//   props<{ callback?: () => void }>()
// );
export const getDocuments = createAction(
  '[Document] Get documents',
  props<{
    newPageSize: number;
    sortedColumn: string;
    sortDirection: string;
    filters: [string,any][];
    newPagingCookie?: string;
    callback?: () => void;
  }>()
);
// export const getDocumentsResolved = createAction(
//   '[Document] Get all documents resolved',
//   props<{ documents: IshtarDocument[] }>()
// );
export const getDocumentsResolved = createAction(
  '[Document] Get documents resolved',
  props<{
    result: IshtarDocument[];
    pagingCookie: string;
    totalRecordCount: number;
    resetPaging?: boolean;
  }>()
);

export const removeDocument = createAction(
  '[Document] Remove a specific document',
  props<{ documentId: string; callback?: () => void }>()
);
export const removeDocumentResolved = createAction(
  '[Document] Remove a specific document resolved',
  props<{ documentIds: string[] }>()
);

export const patchActiveDocument = createAction(
  '[Document] Patch a document title',
  props<{
    document: IshtarDocument;
    callback?: () => void;
  }>()
);
export const patchActiveDocumentResolved = createAction(
  '[Document] Patch a document title resolved',
  props<{ document: IshtarDocument; callback?: () => void }>()
);
