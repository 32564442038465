import { IshtarDocumentBlockTagExtended } from './tags/ishtar-document-block-tag.model';

export class IshtarDocumentBlock {
  ishtarDocsDocumentBlockId!: string;
  title!: string;
  order!: number;
  enabled!: boolean;
  blockSection?: { id: string; name: string };
  document?: { id: string; name: string };

  constructor(obj: Partial<IshtarDocumentBlock>) {
    delete (obj as any).tags;
    delete (obj as any).name;
    delete (obj as any).id;
    Object.assign(this, obj);
  }
}

export class IshtarDocumentBlockExtended {
  ishtarDocsDocumentBlockId!: string;
  title!: string;
  order!: number;
  enabled!: boolean;
  blockSection?: { id: string; name: string };
  document?: { id: string; name: string };
  tags: IshtarDocumentBlockTagExtended[] = [];

  constructor(obj: Partial<IshtarDocumentBlock | IshtarDocumentBlockExtended>) {
    Object.assign(this, obj);
  }
}
