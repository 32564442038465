import { IshtarTemplateService } from '../../api/services/ishtarTemplate.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {mergeMap, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

import {
  getBlockSections,
  getBlockSectionsResolved,
  getBlockSectionTranslations,
  getBlockSectionTranslationsResolved,
  getDocBlockTags,
  getDocBlockTagsResolved,
  getDocumentBlocks,
  getDocumentBlocksResolved,
  getNewDocBlockSections,
  getNewDocBlockSectionsResolved,
  newBlockSection,
  newBlockSectionResolved,
  patchBlockHeaderTranslations,
  patchBlockHeaderTranslationsResolved,
  patchBlockSection,
  patchBlockSectionResolved,
  patchBlockTextTranslations,
  patchBlockTextTranslationsResolved,
  patchDocBlockTags,
  patchDocBlockTagsResolved,
  patchDocumentBlocks,
  patchDocumentBlocksResolved,
} from './blockSection.actions';

@Injectable({ providedIn: 'root' })
export class BlockSectionEffects {
  constructor(
    private actions$: Actions,
    private ishtarTemplateService: IshtarTemplateService
  ) {}
// before paging
  // getBlockSections = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(getBlockSections),
  //     switchMap(({ callback }) =>
  //       this.ishtarTemplateService.getBlockSections().pipe(
  //         switchMap((data) =>
  //           of(getBlockSectionsResolved({ blockSections: data }))
  //         ),
  //         tap(() => console.log('getBlockSectionsResolved')),
  //         tap(() => (callback ? callback() : undefined))
  //       )
  //     )
  //   )
  // );


  getBlockSections = createEffect(() =>
    this.actions$.pipe(
      ofType(getBlockSections),
      mergeMap(
        ({
           newPageSize,
           sortedColumn,
           sortDirection,
           filters,
           newPagingCookie,
           callback,
         }) =>
          this.ishtarTemplateService
            .getBlockSections(
              newPageSize,
              sortedColumn,
              sortDirection,
              filters,
              newPagingCookie
            )
            .pipe(
              switchMap(({ result, pagingCookie, totalRecordCount }) =>
                of(
                  getBlockSectionsResolved({
                    result,
                    pagingCookie,
                    totalRecordCount,
                    resetPaging: !newPagingCookie,
                  })
                )
              ),
              tap(() => (callback ? callback() : undefined)),
              catchError((e) => [])
            )
      )
    )
  );


  newBlockSection = createEffect(() =>
    this.actions$.pipe(
      ofType(newBlockSection),
      switchMap(({ callback }) =>
        this.ishtarTemplateService.newBlockSections().pipe(
          switchMap((data) =>
            of(newBlockSectionResolved({ blockSectionResponse: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getDocumentBlocks = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentBlocks),
      switchMap(({ callback }) =>
        this.ishtarTemplateService.getDocumentBlocks().pipe(
          switchMap((data) =>
            of(getDocumentBlocksResolved({ documentBlocks: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getBlockSectionTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(getBlockSectionTranslations),
      switchMap(({ callback }) =>
        this.ishtarTemplateService.getBlockSectionTranslations().pipe(
          switchMap((data) =>
            of(getBlockSectionTranslationsResolved({ response: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchBlockSection = createEffect(() =>
    this.actions$.pipe(
      ofType(patchBlockSection),
      switchMap(({ BlockSection, callback }) =>
        this.ishtarTemplateService.patchBlockSection(BlockSection).pipe(
          switchMap((data) =>
            of(patchBlockSectionResolved({ blockSection: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchBlockHeaderTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(patchBlockHeaderTranslations),
      switchMap(({ blockHeaderTranslations, callback }) =>
        this.ishtarTemplateService
          .patchHeaderTranslations(blockHeaderTranslations)
          .pipe(
            switchMap((data) =>
              of(
                patchBlockHeaderTranslationsResolved({
                  response: data,
                })
              )
            ),
            tap(() => (callback ? callback() : undefined))
          )
      )
    )
  );

  patchBlockTextTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(patchBlockTextTranslations),
      switchMap(({ blockTextTranslations, callback }) =>
        this.ishtarTemplateService.patchTextTranslations(blockTextTranslations).pipe(
          switchMap((data) =>
            of(
              patchBlockTextTranslationsResolved({
                response: data,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getNewDockBlockSections = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewDocBlockSections),
      switchMap(({ documentId, callback }) =>
        this.ishtarTemplateService.getNewDocBlockSections(documentId).pipe(
          switchMap((data) =>
            of(getNewDocBlockSectionsResolved({ response: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getDocumentBlockTags = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocBlockTags),
      switchMap(({ documentBlockId, callback }) =>
        this.ishtarTemplateService.getDocumentBlockTag(documentBlockId).pipe(
          switchMap((data) => of(getDocBlockTagsResolved({ response: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchDocumentBlockTags = createEffect(() =>
    this.actions$.pipe(
      ofType(patchDocBlockTags),
      switchMap(({ documentBlockTags, callback }) =>
        this.ishtarTemplateService.patchDocumentBlockTag(documentBlockTags).pipe(
          switchMap((data) =>
            of(patchDocBlockTagsResolved({ response: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchDocumentBlocks = createEffect(() =>
    this.actions$.pipe(
      ofType(patchDocumentBlocks),
      switchMap(({ documentBlocks, callback }) =>
        this.ishtarTemplateService.patchDocumentBlock(documentBlocks).pipe(
          switchMap((data) =>
            of(patchDocumentBlocksResolved({ response: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );
}
