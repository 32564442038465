import {
  Component,
  HostListener,
  Input,
  OnDestroy, OnInit,
} from '@angular/core';
import {QuillEditorComponent, SelectionChange} from 'ngx-quill';
import {takeUntil, filter,  Subject, BehaviorSubject} from 'rxjs';
import {CoreModule} from 'src/app/core/core.module';
import {TagFacade} from 'src/app/core/store/tags/tags.facade';
import {TextEditorComponent} from '../text-editor/text-editor.component';
import {v4 as uuid} from 'uuid';
import {MatSelectChange} from '@angular/material/select';
import {CustomBlot, CustomEmbedBlot} from '../text-editor/blots';
import {
  IshtarTagType,
  TagTypeFunction,
} from 'src/app/core/domain/models/tags/ishtar-tag-type.model';
import {CustomBlotTypes} from "../../../core/domain/enums/custom-blot-type.enum";
import { vartranslations$} from "../../../core/api/data/data.observables";
import { IshtarTag } from 'src/app/core/domain/models/tags/ishtar-tag.model';

@Component({
  standalone: true,
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss'],
  imports: [CoreModule],
})
export class TagEditorComponent implements OnInit, OnDestroy {
  @Input() textEditor?: TextEditorComponent;

  //translations for the user interface
  translations = vartranslations$.value;

  // Local map with all blots, it groups all blots per getTitle() and stores an index for each array
  // key string is the title, value number is pair of an index and a list of blots
  // index is the active index for the list
  allBlots = new Map<string, [number, any[]]>();

  destroy$ = new Subject<void>();

  // Active blot in tag editor
  activeBlot?: any;

  // selected range from text editor, gets updated on change
  selectedRange?: SelectionChange;

  // Variables for activeblot editor
  isMultiBlock = false;
  isMultiBlockEnabled = true;
  addSelection = false;
  actionName = '';
  newTextActionError?: string;

  // Selected new action
  selectedAction?: IshtarTag;

  // New blots dropdown
  fillablesDropdown: IshtarTag[] = [];
  hideablesDropdown:IshtarTag[] = [];
  footnotesDropdown: IshtarTag[] = [];

  possibleTagTypes = Object.values(CustomBlotTypes);
  newAction = '';
  newActionName = '';
  newActionValue = '';


  keyboardEvent = new BehaviorSubject<KeyboardEvent>(new KeyboardEvent(''));

  constructor(
    private tagFacade: TagFacade
  ) {}

  ngOnInit() {
    console.log("ngOnInit")
    if (this.fillablesDropdown.length == 0 && this.hideablesDropdown.length == 0 && this.footnotesDropdown.length == 0) {
      this.tagFacade.tags$
        .pipe(
          takeUntil(this.destroy$),
          filter((t) => t != undefined),
          filter((t) => t.length != 0)
        )
        .subscribe((t) => {
          t.forEach((tagtype) => {
            this.tagTypesInit(tagtype);
          });
        });
    }
    console.log("TAGS",this.tagFacade.tagTypes$)

  }
// todo when ishtartag has a property value, add it to the tag of footnote
  private tagTypesInit(tag: IshtarTag) {
    console.log("tagtypesinit")
    switch (tag.tagType.name) {
      case TagTypeFunction.Footnote:
        this.footnotesDropdown.push(tag);
        break;
      case TagTypeFunction.Fillable:
        this.fillablesDropdown.push(tag);
        break;
      case TagTypeFunction.Hideable:
        this.hideablesDropdown.push(tag);
        break;
      default: {
        break;
      }
    }
  }

// Undo breaks most blots so reload everything
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey && e.key == 'z') {
      // undo
      this.loadBlots();
    }
  }

  // Insert a blot with the same data as activeBlot on range selChange
  // todo duplicate and change this function for fillable and footnote
  addSelectionRange(selChange: SelectionChange) {
    if (!this.activeBlot || !selChange.range) return;
    this.addSelection = false;

    console.log(this.activeBlot);

    const title = this.activeBlot.domNode.getAttribute('title');
    console.log(title);
    this.insertCustomBlot(
      selChange.range.index,
      selChange.range.length,
      this.activeBlot.domNode.localName,
      {
        title: title,
        id: uuid(),
        multi: this.activeBlot.multi,
      }

    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initSelectionChanged(editor: QuillEditorComponent) {
    editor.onSelectionChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((selChange) => {

        if (!selChange.range) return;
        if (this.addSelection && this.activeBlot) {
          this.addSelectionRange(selChange);
          return;
        }

        this.selectedRange = selChange;

        if (
          this.activeBlot &&
          selChange.source == 'user' &&
          selChange.range.length == 0
        ) {
          this.activeBlot = undefined;
        }

        console.log(this.keyboardEvent.value.key);

        // if (this.activeBlot && this.keyboardEvent.value.key == 'shift') {
        //   const initialI = this.textEditor!.editor.quillEditor.getIndex(
        //     this.activeBlot
        //   );
        //   const initialL = this.activeBlot.length();

        //   this.textEditor?.editor.quillEditor.focus();
        //   this.textEditor?.editor.quillEditor.setSelection({
        //     index: initialI,
        //     length: initialL,
        //   });
        // }

        if (this.activeBlot && this.keyboardEvent.value.shiftKey) {
          this.updateBlotSelRange(selChange);
        }
      });

  }


  // Regsister blot with allBlots
  registerBlot(blot: any, setIndex = -1) {
    const title = this.getBlotTitle(blot);
    if (!this.allBlots.has(title))
      this.allBlots.set(title, [0, [blot]]); // new key in dict
    else {
      const existingblots = this.allBlots.get(title)![1];
      existingblots.push(blot);
      existingblots.sort((a, b) => {
        console.log(a, b);
        return (
          a.domNode.offsetTop - b.domNode.offsetTop ||
          a.domNode.offsetLeft - b.domNode.offsetLeft
        );
      });
      if (setIndex < 0) setIndex = existingblots.findIndex((bl) => bl == this);

      this.allBlots.set(title, [setIndex, existingblots]); // update with new index
    }

    // Select function
    blot['domNode'].addEventListener('click', (e: any) => {
      this.selectedAction = undefined;
      const b = blot;

      console.log('pqodijf', b);
      this.selectBlotDropdown(b);
      this.activeBlot = b;
    });
    return blot;
  }

  getBlotTitle(blot: any) {
    if (blot == undefined) return '';
    if (blot.domNode.localName == 'footnote') return 'footnote';
    if (blot.domNode.localName == 'price')
      return (Object.values(blot.formats()) as any)[1];
    return (Object.values(blot.formats())[0] as any).title;
  }

  toggleTagTypeMultiblock() {
    console.log(this.selectedAction);
    if (
      this.selectedAction &&
      (this.selectedAction.tagType.name == CustomBlotTypes.FILLABLE ||
        this.selectedAction.tagType.name == CustomBlotTypes.HIDEABLE)
    )
      this.tagFacade.patchTagType(
        new IshtarTagType({
          ishtarDocsTagTypeId: this.selectedAction.ishtarDocsTagId,
          name: this.selectedAction.name,
          isMultiBlock: this.isMultiBlock,
        })
      );
  }

  selectNewBlotDropdownEvent(event: MatSelectChange) {
    if (event.value.id == "new") this.activeBlot = undefined;
    this.isMultiBlock = event.value.isMultiBlock;
    this.isMultiBlockEnabled =
      event.value.blotType == CustomBlotTypes.FILLABLE ||
      event.value.blotType == CustomBlotTypes.HIDEABLE;
  }

  selectBlotDropdown(blot: any) {
    if (blot == undefined) throw 'selecting undefined blot';
    const title = this.getBlotTitle(blot);
    const blotsOfThisType = this.allBlots.get(title);
    if (!blotsOfThisType) throw 'Selecting unregistered blot type';
    const index = blotsOfThisType[1].findIndex((b) => {
      return blot.domNode.isEqualNode(b.domNode);
    });
    if (index == -1) throw 'No item in allblots found';
    blotsOfThisType[0] = index;
    this.allBlots.set(title, blotsOfThisType); // update with new index

    const initialI = this.textEditor!.editor.quillEditor.getIndex(blot);
    const initialL = blot.length();

    this.textEditor?.editor.quillEditor.focus();
    this.textEditor?.editor.quillEditor.setSelection({
      index: initialI,
      length: initialL,
    });
  }

  clearMap() {
    this.allBlots.clear();
  }

  canSkipBlot(blot: any): boolean {
    if (blot == undefined) return false;
    const title = this.getBlotTitle(blot);
    const blotsOfThisType = this.allBlots.get(title);
    if (!blotsOfThisType || !blotsOfThisType[1].length) return false;
    return blotsOfThisType[1].length > 1;
  }

  selectNextBlot(direction = 1) {
    if (!this.activeBlot) return;
    const next = this.getNext(this.activeBlot, direction);
    this.selectBlot(next);
  }

  getNext(blot: any, direction = 1) {
    if (!this.canSkipBlot(blot)) return this;
    const title = this.getBlotTitle(blot);
    const blotsOfThisType = this.allBlots.get(title);
    if (!blotsOfThisType) throw 'No blots with this type in register';
    let index = blotsOfThisType[0] + direction;
    if (index >= blotsOfThisType[1].length) index = 0;
    if (index < 0) index = blotsOfThisType[1].length - 1;
    const returnblot = blotsOfThisType[1][index];

    this.selectBlotDropdown(returnblot); // update with new index
    console.log(
      'NEXT: ',
      blotsOfThisType[1].length,
      index,
      direction,
      blotsOfThisType[1][index]
    );
    return returnblot;
  }

  getSelectableBlots(): any[] {
    const returnList: any[] = [];
    const blotsLists = Array.from(this.allBlots.values());
    for (let index = 0; index < blotsLists.length; index++) {
      const blots = blotsLists[index];

      returnList.push(blots[1][blots[0]]);
      // return item with first item in tuple as index
    }
    return returnList;
  }

  insertCustomBlot(index: number, length: number, name: string, data: any) {
    if (length < 1) {
      console.log('length is 0 or less');
      return;
    }
    console.log('before inserting custom blot', this.textEditor?.editor.quillEditor);
    this.textEditor?.editor.quillEditor.formatText(index, length, name, data);
    const blot = this.getBlotFromIndex(index);
    const customblot = this.registerBlot(blot);
    this.selectBlotDropdown(customblot);
    console.log('after inserting custom blot', this.textEditor?.editor.quillEditor.getContents());
    console.log('after inserting custom blot', this.textEditor?.editor.quillEditor.getFormat());
  }

  submitTextAction() {
    console.log("submit clicked in tag editor", this.selectedAction);
    if (this.isNewTag()) {
      // if (this.actionName.trim() == '')
        return;
      // return this.tagFacade.createTagType(
      //   new IshtarTagType({name: this.actionName})
      // );
    }

    if (
      !this.checkIndexSelectedRange() ||
      !this.selectedAction ||
      !this.textEditor ||
      !this.selectedRange?.range
    )
      throw 'Selected an action but no action was registered lmao';

    return this.putTagInEditor(this.selectedAction);
  }

  private putTagInEditor(givenAction: IshtarTag )
  {
    switch (givenAction.tagType.name) {
      case CustomBlotTypes.FILLABLE:
        if (this.checkChosenSpot())
          return this.insertCustomBlot(
            this.selectedRange!.range!.index + this.selectedRange!.range!.length,
            0,
            'fillable',
            {
              title: givenAction.name,
              id: givenAction.ishtarDocsTagId,
              multi: false,
            }
          );
        break;
      case CustomBlotTypes.HIDEABLE:
        return this.insertCustomBlot(
          this.selectedRange!.range!.index,
          this.selectedRange!.range!.length,
          'hideable',
          {
            title: givenAction.name,
            id: givenAction.ishtarDocsTagId,
            multi: false,
          }
        );
        break;
      case CustomBlotTypes.FOOTNOTE:
        if (this.checkChosenSpot())
        return this.insertCustomBlot(
          this.selectedRange!.range!.index + this.selectedRange!.range!.length,
          0,
          'footnote',
          {
            title: givenAction.name,
            id: givenAction.ishtarDocsTagId,
            multi: false,
          }
        );
        break;
      default:
        throw 'this should never happen';
    }
  }

  private checkMinSelectedRange() {
    if (!this.selectedRange?.range || this.selectedRange.range.length == 0) {
      this.newTextActionError = 'Select an area in the editor to add the tag';
      return false;
    } else {
      this.newTextActionError = undefined;
      return true;
    }
  }
  private checkChosenSpot() {
    if (!this.selectedRange?.range ) {
      this.newTextActionError = 'Select an area in the editor to add the tag';
      return false;
    } else {
      this.newTextActionError = undefined;
      return true;
    }
  }

  private checkIndexSelectedRange() {
    if (!this.selectedRange?.range) {
      console.log('no range', this.selectedRange)
      this.newTextActionError = 'Select a place in the editor to add the tag';
      return false;
    } else {
      this.newTextActionError = undefined;
      return true;
    }
  }

  insertPriceList(index: number, data: any) {
    if (!this.selectedRange || !this.textEditor?.editor || !this.selectedAction)
      return;
    console.log(data);
    this.textEditor.editor.quillEditor.insertEmbed(index, 'price', data);
    console.log('baddabimbaddaboom');

    const blot = this.findBlot(
      'price',
      this.selectedAction.name,
      this.selectedAction.ishtarDocsTagId
    );
    console.log('pricelistblot', blot);
    const existingblot = this.registerBlot(blot);

    // Select function for footnote embed
    blot.domNode.addEventListener('click', (e: any) => {
      const eb = existingblot;
      this.selectBlotDropdown(eb);
    });
  }

  insertFootnote(footnoteText: string) {
    if (!this.selectedRange || !this.textEditor?.editor) return;
    const hasSelection = this.selectedRange != undefined;

    if (!hasSelection) return;
    if (footnoteText.length <= 0) return;
    const range = this.textEditor.editor.quillEditor.getSelection(true);
    const id = uuid();
    this.textEditor.editor.quillEditor.insertEmbed(range.index, 'footnote', {
      text: footnoteText,
      id: id,
      multi: false,
    });

    const blot = this.findBlot('footnote', footnoteText, id);
    console.log('footnoteblot', blot);
    const existingblot = this.registerBlot(blot);
  }

  updateRangeAdjusted(index: number, length: number) {
    const range = this.selectedRange;
    if (!range?.range || !range.oldRange) return;
    range.oldRange.index = range.range.index;
    range.oldRange.length = range.range.length;
    range.range.index = range.range.index + index;
    range.range.length = range.range.length + length;
    this.updateBlotSelRange(range);
    this.textEditor?.editor.quillEditor.setSelection(range.range);
  }

  // Update active blot to new range selChange
  // If beginpoint and endpoint are different it will fail
  updateBlotSelRange(selChange: SelectionChange) {
    if (
      selChange.range == undefined ||
      selChange.oldRange == undefined ||
      !this.activeBlot ||
      !this.textEditor
    )
      return;

    const formats = this.textEditor.editor.quillEditor.getFormat(
      selChange.oldRange.index,
      selChange.oldRange.length
    );

    const activeI = this.textEditor.editor.quillEditor.getIndex(
      this.activeBlot
    );
    const activeL = this.activeBlot.length();

    const selChangeEndPos = selChange.range.index + selChange.range.length;
    const BlotEndPos = activeI + activeL;

    const title = this.getBlotTitle(this.activeBlot);
    const allBlotsEntry = this.allBlots.get(title)!;

    // If start and end pos are different we will cancel and unselect activeBlot
    if (
      selChange.range.index - activeI > 1 &&
      selChangeEndPos - BlotEndPos > 1 &&
      selChange.oldRange.index == 0 &&
      selChange.oldRange.length == 0
    ) {
      {
        this.activeBlot = undefined;
        console.log(
          'Threw activeblot',
          selChange.range,
          selChange.oldRange,
          activeI,
          activeL
        );
        return;
      }
    }

    let i = 0;
    let l = 0;
    let grow = undefined;

    let newI = undefined;
    if (activeI == selChange.range.index && activeL < selChange.range.length) {
      // add behind
      i = activeI + activeL;
      l = selChange.range.length - activeL;
      grow = true;
    } else if (activeI > selChange.range.index) {
      // add front
      i = selChange.range.index;
      l = activeI - selChange.range.index;
      grow = true;
      newI = selChange.range.index + 1;
    }

    if (activeI == selChange.range.index && selChange.range.length < activeL) {
      // remove behind
      i = selChange.range.index + selChange.range.length;
      l = activeL - selChange.range.length;
      grow = false;
      newI = activeI + 1;
    }
    if (selChange.range.index > activeI) {
      // remove front
      i = activeI;
      l = selChange.range.index - activeI;
      grow = false;
      newI = activeI + l + 1;
    }

    const removed: any[] = [];

    if (grow == undefined)
      throw `THIS SHOULD NEVER HAPPEN | blot: ${activeI} ${activeL}, range: ${selChange.range.index}, ${selChange.range.length}`;
    else if (grow) {
      console.log(i, l);
      for (const [blotName, format] of Object.entries(formats)) {
        this.textEditor.editor.quillEditor.formatText(i, l, blotName, format);
      }
    } else {
      for (const [, format] of Object.entries(formats)) {
        removed.push(
          this.textEditor.editor.quillEditor.removeFormat(i, l, format)
        );
      }
    }

    if (newI) {
      this.activeBlot = this.getBlotFromIndex(newI);
      const index = allBlotsEntry[0];
      const blottypelist = allBlotsEntry[1];
      blottypelist[index] = this.activeBlot;
      this.allBlots.set(title, [index, blottypelist]);

      if (this.activeBlot) {
        this.activeBlot.domNode.addEventListener('dblclick', (e: any) => {
          const b = this.activeBlot;
          console.log('click', b);
          if (!b) return;
          this.selectBlot(b);
        });
      }
    }
  }

  editorKeyDown(e: KeyboardEvent) {
    this.keyboardEvent.next(e);
  }

  removeActiveBlot() {
    if (!this.activeBlot || !this.textEditor) return;
    const i = this.textEditor.editor.quillEditor.getIndex(this.activeBlot);
    const l = this.activeBlot.length();
    // Get all the blots in the text selection
    const formats = this.textEditor.editor.quillEditor.getFormat(i, l);
    // Delete the blot format you are targeting
    delete formats[this.activeBlot.statics.blotName];
    // Remove all formats
    this.textEditor.editor.quillEditor.removeFormat(i, l);
    // Add back the remaining formats
    for (const [blotName, format] of Object.entries(formats)) {
      this.textEditor.editor.quillEditor.formatText(i, l, blotName, format);
    }
    this.loadBlots();
    this.activeBlot = undefined;
    this.addSelection = false;
  }

  /// HTML functions

  selectBlotDropdownEvent(e: MatSelectChange) {
    this.selectBlot(e.value);
    this.addSelection = false;
  }

  getActiveBlotName(blot: any) {
    return blot.constructor.name;
  }

  isEmbed(blot?: any) {
    if (!blot) return false;
    return blot instanceof CustomEmbedBlot;
  }

  showTextActionContent() {
    if (!this.selectedAction) return false;
    switch (this.selectedAction.tagType.name) {
      case CustomBlotTypes.FOOTNOTE:
        return true;
      default:
        return false;
    }
  }

  // Select blot to edit in tag editor
  selectBlot(blot: any) {
    if (blot == undefined) throw 'Selecting undefined blot';
    this.selectBlotDropdown(blot);
    this.activeBlot = blot;
  }

  // Get the custom blot on the cursor, if it is not a custom blot return undefined
  getBlotFromIndex(index: number): any {
    const blot = this.textEditor?.editor.quillEditor.getLeaf(index)[0];

    if (blot instanceof CustomBlot) {
      return blot;
    }
    if (blot.parent instanceof CustomBlot) {
      return blot.parent;
    }
    if (blot.next instanceof CustomBlot) {
      return blot.next;
    }

    return undefined;
  }

  findBlot(blotType: string, title?: string, id?: string) {
    let query = blotType;
    if (title || id) {
      query += '[';
      if (title) query += `title="${title}"`;
      if (id) id += `data-id="${id}"`;
      query += ']';
    }

    console.log(query);
    const data: any[] = Array.from(
      this.textEditor?.editorRef.nativeElement.querySelectorAll(query)
    );
    const blot = data[0].__blot.blot;
    return blot;
  }

  // Search everything under the editor for custom tags and load them into the tag editor
  loadBlots() {

    let nodes: any[] = [];
    Object.values(CustomBlotTypes).forEach(
      (name) =>
        (nodes = nodes.concat(
          Array.from(this.textEditor?.editorRef.nativeElement.querySelectorAll(name))
        ))
    );
    this.clearMap();
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];

      this.registerBlot(node.__blot.blot, 0);
    }
  }

  isNewTag() {
    if (this.selectedAction == undefined) return false;
    if (this.selectedAction.ishtarDocsTagId == "new") return true;
    return false;
  }


  addTextAction() {
    //todo: add to db if form is valid , now tags are getting added to db in the backend

    const action =
      {
        id: uuid(),
        name: this.newActionName,
        isMultiBlock: false,
        blotType: this.newAction,
        value : this.newActionValue ? this.newActionValue : undefined ,
      }
      console.log(action);
    console.log(this.newActionName);
  }
}
