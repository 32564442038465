export const environment = {

  production: false,
  instance: 'https://login.microsoftonline.com/',
  clientId: '17f621ea-f6ba-4284-8a68-ce170447b798',
  tenantId: 'common',
  authority: 'https://login.microsoftonline.com/common',
  ishtarFunctions:'https://functions.dev.ishtar365.com/',
  ishtarFunctionsKey:'Rl1x2qHDmM4-SvoNyzQDb-SRDTv-qXStKozJkQlMzF9lAzFu6Qk4VA==',
  ishtarFunctionsScope: 'api://a5341d23-1ecf-48e9-9fc6-55fae87ed91e/user_impersonation',
  ishtar365: 'https://portal.dev.ishtar365.com',
  appIdentifier: 'template.dev',
  cdnAppIconUrl: 'https://ishtar365storage.blob.core.windows.net/icons/Template_color.svg',
};
