import * as fromSideBar from './side-bar/side-bar.reducer';
import * as fromLoader from './loader/loader.reducer';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { LoaderEffects } from './loader/loader.effects';

export const featureKey = 'sharedFeature';

export interface SharedState {
  [fromSideBar.featureSlice]: fromSideBar.State;
  [fromLoader.featureSlice]: fromLoader.State;
}

export const reducers = new InjectionToken<ActionReducerMap<SharedState>>(
  featureKey,
  {
    factory: () => ({
      [fromSideBar.featureSlice]: fromSideBar.Reducer,
      [fromLoader.featureSlice]: fromLoader.Reducer,
    }),
  }
);
export const effects = [LoaderEffects];
