import { createAction } from '@ngrx/store';
import * as fromShared from './shared-feature/shared.feature';
import * as fromCore from '../../core/store/core.feature';

export interface AppState {
  [fromShared.featureKey]: fromShared.SharedState;
  [fromCore.featureKey]: fromCore.CoreState;
}

export const NOOP_ACTION = createAction('[Ishtar.365] NO-OP');
