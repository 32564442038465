<div class="textEditorSettings lightborder">
  <p class="textSettingLabel">{{translations.textAction}}</p>
  <div class="newtextActionForm lightborder">
    <div class="mainarea">


      <div class="actiontypeselector">
        <mat-form-field appearance="fill" class="textActionMultiselect">
          <mat-label>{{translations.actionType}}</mat-label>
          <mat-select
            [(ngModel)]="selectedAction"
            (selectionChange)="selectNewBlotDropdownEvent($event)"
          >
            <mat-option
              [value]="{
                  ishtarDocsTagId: 'new',
                  name: 'new'
                }"
            >
              {{translations.newAction}}
            </mat-option>
            <hr/>

            <mat-optgroup label="{{translations.fillable}}">
              <mat-option
                *ngFor="let item of fillablesDropdown"
                [value]="item"
              >
                {{ item.name }}
              </mat-option>
            </mat-optgroup>

            <mat-optgroup label="{{translations.hideable}}">
              <mat-option
                *ngFor="let item of hideablesDropdown"
                [value]="item"
              >
                {{ item.name }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="FOOTNOTE">
              <mat-option
                *ngFor="let item of footnotesDropdown"
                [value]="item"
              >
                {{ item.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <div class="button" *ngIf="!isNewTag(); else newTagButton">
        <button
          mat-raised-button
          color="primary"
          class="textSettingSubmit"
          (click)="submitTextAction()"
        >
          {{translations.submit}}
        </button>
        </div>

        <ng-template #newTagButton >
          <button
            mat-raised-button
            color="primary"
            class="textSettingSubmit"
            (click)="addTextAction()"
          >
            {{translations.addAction}}
          </button>
        </ng-template>

      </div>

<!--      <mat-form-field-->
<!--        class="textAction footnotefield"-->
<!--        *ngIf="showTextActionContent()"-->
<!--      >-->
<!--        <mat-label>{{translations.actionName}}</mat-label>-->
<!--        <textarea-->
<!--          matInput-->
<!--          [(ngModel)]="actionName"-->
<!--          placeholder="Name"-->
<!--        ></textarea>-->
<!--      </mat-form-field>-->

      <div *ngIf="newTextActionError" class="newtextactionerror">
        <p>{{ newTextActionError }}</p>
      </div>

      <mat-checkbox
        class="checkbx"
        (change)="toggleTagTypeMultiblock()"
        [checked]="isMultiBlock"
        [disabled]="!isMultiBlockEnabled"
      >
        <p>{{translations.multiblock}}</p>
      </mat-checkbox>
    </div>
  </div>


  <div *ngIf="activeBlot" class="action-content d-flex flex-column h-100">
    <p class="textSettingLabel">{{translations.selected}}: {{ getBlotTitle(activeBlot) }}</p>
    <div class="textActionForm lightborder h-100">

      <div class="expandbuttons">
        <button
          title="Previous Action"
          mat-mini-fab
          color="white"
          (click)="this.selectNextBlot(-1)"
          [disabled]="!canSkipBlot(activeBlot)"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <div class="selector">
        <p>    .    </p>
        <mat-form-field appearance="fill" class="textActionMultiselect">
          <mat-label>{{translations.actionTitle}}</mat-label>
          <mat-select
            [(ngModel)]="activeBlot"
            (selectionChange)="selectBlotDropdownEvent($event)"
          >
            <mat-option
              *ngFor="let blot of getSelectableBlots()"
              [value]="blot"
            >{{ getBlotTitle(blot) }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="expandbuttons">
        <button
          title="Next Action"
          mat-mini-fab
          color="white"
          [disabled]="!canSkipBlot(activeBlot)"
          (click)="selectNextBlot()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>

      <div class="expandbuttons">
        <button
          title="Add content at front of action"
          mat-mini-fab
          color="white"
          [disabled]="!activeBlot || isEmbed(activeBlot)"
          (click)="updateRangeAdjusted(-1, 1)"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          title="Remove content from front of action"
          mat-mini-fab
          color="white"
          [disabled]="!activeBlot || isEmbed(activeBlot)"
          (click)="updateRangeAdjusted(1, -1)"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <div class="mainarea">
        <button
          class="cancelbutton"
          mat-raised-button
          color="warn"
          (click)="removeActiveBlot()"
          type="button"
          [disabled]="!activeBlot"
        >
          {{translations.delete}}
        </button>

        <button
          class="addbutton"
          [ngClass]="addSelection ? 'activebutton' : ''"
          mat-raised-button
          color="green"
          type="button"
          (click)="addSelection = !addSelection"
          [disabled]="!activeBlot?.statics.multiBlock"
        >
          {{translations.addSelection}}
        </button>
      </div>
      <div class="expandbuttons">
        <button
          title="Remove content from back of action"
          mat-mini-fab
          color="white"
          [disabled]="!activeBlot || isEmbed(activeBlot)"
          (click)="updateRangeAdjusted(0, 1)"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button
          title="Add content at back of action"
          mat-mini-fab
          color="white"
          [disabled]="!activeBlot || isEmbed(activeBlot)"
          (click)="updateRangeAdjusted(0, -1)"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>

      <mat-form-field
        class="textActionContent expand"
        *ngIf="!isEmbed(activeBlot)"
      >
        <mat-label>{{translations.actionContent}}</mat-label>
        <textarea
          [(ngModel)]="activeBlot?.domNode.textContent"
          matInput
          [disabled]="!activeBlot"
          placeholder="{{translations.insideTag}}"
          class="scrollbar"
        ></textarea>
      </mat-form-field>

      <mat-form-field
        class="textActionContent expand"
        *ngIf="isEmbed(activeBlot)"
      >
        <mat-label>{{translations.actionContent}}</mat-label>
        <textarea
          [(ngModel)]="activeBlot?.domNode.attributes['title'].value"
          matInput
          [disabled]="!activeBlot"
          placeholder="{{translations.data}}"
          class="scrollbar"
        ></textarea>
      </mat-form-field>


    </div>
  </div>

  <div *ngIf="isNewTag()" class="action-content d-flex flex-column h-100">
    <p class="textSettingLabel">{{translations.newAction}}</p>
    <div class="newerTextActionForm lightborder h-100">

      <div class="selector">
        <mat-form-field class="textActionMultiselect">
          <mat-label>{{translations.actionType}}</mat-label>
          <mat-select
            [(ngModel)]="newAction"
          >
            <mat-option
              *ngFor="let item of possibleTagTypes"
              [value]="item"
            >
              {{ item }}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>



      <mat-form-field class="mainarea">
        <mat-label>{{translations.name}}</mat-label>
        <input
          matInput
          required
          placeholder=""
          [(ngModel)] ="newActionName"
        />
      </mat-form-field>


      <mat-form-field
        *ngIf="newAction === 'Footnote'"
        class="textActionContent expand"
      >
        <mat-label>{{translations.actionContent}}</mat-label>
        <textarea

          matInput
          placeholder="{{translations.insideTag}}"
          class="scrollbar"
          [value]="newActionValue"
        ></textarea>
      </mat-form-field>





    </div>
  </div>


</div>
