import {Component, OnInit} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {UnauthorizedAccessComponent} from './unauthorized-access/unauthorized-access';

import {MicrosoftAuthenticationGuard, GeneralLayoutComponent, SideBarAction,}
  from 'processdelight-angular-components'
import {environment} from "../environments/environment";
import {first, map} from "rxjs/operators";
import {filter} from "rxjs";
import {varlicense$, vartranslations$} from "./core/api/data/data.observables";
import {TextBlocksComponent} from "./doc-settings/text-blocks/text-blocks.component";
import {CompanyDetailsComponent} from "./doc-settings/company-details/company-details.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";


@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, CoreModule, SharedModule, GeneralLayoutComponent],
})
export class AppComponent implements OnInit {
  //translations for the user interface
  translations = vartranslations$.value;

  title = 'IshtarTemplate';
  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');

  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));

  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));

  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));

  actions: SideBarAction[] = [];


  ngOnInit(): void {
    varlicense$
      .pipe(
        filter((l) => !!l && !!Object.keys(l).length),
        first()
      )
      .subscribe((license) =>
        license?.licenses.some((i) => i.productName == 'Ishtar.Template'
          && i.isAdmin)
          ? this.actions.push(
            new SideBarAction({
              title: this.translations.documents || 'Documents',
              icon: 'description',
              iconOutline: true,
              route: ['documents'],
            }),

            new SideBarAction({
              title: vartranslations$.value.settings || 'Settings',
              icon: 'settings',
              iconOutline: true,
              route: ['settings/textblocks'],
            })
          )
          : undefined
      );
    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace('color', params.get('appColor')!);
    const tabIconLink = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (tabIconLink)
      tabIconLink.href = this.appLogo;
  }
}

export const appRoutes: Routes = [
    {
      path: '',
      loadChildren: () =>
        import('processdelight-angular-components').then((m) => m.MsalModule),
      // neccessary to load the msal module
    },
    {
      path: 'documents',
      canActivate: [MicrosoftAuthenticationGuard],
      loadComponent: () =>
        import('./doc-select/doc-select.component').then(
          (docSelect) => docSelect.DocSelectComponent
        ),
      data: {breadcrumbTitle: 'Documents'},
    },
    {
      path: 'document/:id',
      canActivate: [MicrosoftAuthenticationGuard],
      loadComponent: () =>
        import('./doc-editor/doc-editor.component').then(
          (docSelect) => docSelect.DocEditorComponent
        ),
    },
    {
      path: 'settings',
      canActivate: [MicrosoftAuthenticationGuard],
      loadComponent: () =>
        import('./doc-settings/doc-settings.component').then(
          (settings) => settings.DocSettingsComponent
        ),
      data: {breadcrumbTitle: 'Settings'},
      children: [
        {
          path: 'text_blocks',
          component: TextBlocksComponent,
          data: {breadcrumbTitle: 'Text Blocks'},
        },
        {
          path: 'company_details',
          component: CompanyDetailsComponent,
          data: {breadcrumbTitle: 'Company Details'},
        },
        {
          path: '**',
          redirectTo: 'text_blocks',
        }
      ],
    },
    {
      path: '404', component: PageNotFoundComponent
    },
    {
      path: '401', component: UnauthorizedAccessComponent
    },
    {
      path: '**', redirectTo: '404',
    },
  ]
;





