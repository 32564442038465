import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.reducer';

const loaderState = (state: AppState) => state.sharedFeature.loader;
const isLoading = createSelector(
  loaderState,
  (state) => state.actions.length > 0
);
const getLoaderText = createSelector(loaderState, (state) =>
  state.actions.reduce((acc, a) => acc + a.text + '\n', '').trim()
);
export const loaderSelectors = {
  isLoading,
  getLoaderText,
};
