import { IshtarTagType } from './ishtar-tag-type.model';

export class IshtarTag {
  ishtarDocsTagId!: string;
  name?: string;
  tagType!: { id: string; name: string };
  blockSection?: { id: string; name: string };

  constructor(obj: Partial<IshtarTag>) {
    Object.assign(this, obj);
  }
}

export class IshtarTagExtended {
  ishtarDocsTagId!: string;
  name?: string;
  tagType?: IshtarTagType;
  blockSection?: { id: string; name: string };

  constructor(obj: Partial<IshtarTagExtended>) {
    Object.assign(this, obj);
  }
}
