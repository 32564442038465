import { createSelector } from '@ngrx/store';
import { AppState } from '../core.feature';

const companyDetailState = (state: AppState) =>
  state.coreFeature.companyDetails;

const getCompanyDetails = createSelector(
  companyDetailState,
  (state) => state.companyDetails
);

const getNewCompanyDetail = createSelector(
  companyDetailState,
  (state) => state.newCompanyDetails
);

const getContacts = createSelector(
  companyDetailState,
  (state) => state.contacts
);

const getCountryTranslations = createSelector(
  companyDetailState,
  (state) => state.countryTranslations
);
const getCountries = createSelector(
  companyDetailState,
  (state) => state.countries
);

export const companyDetailSelectors = {
  getCompanyDetails,
  getNewCompanyDetail,
  getContacts,
  getCountryTranslations,
  getCountries,
};
