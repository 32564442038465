import {createAction, props} from '@ngrx/store';
import {
  IshtarCompanyDetail,
  IshtarCompanyDetailExpanded,
  IshtarCityTranslation,
} from '../../domain/models/company_details_page/ishtar-companydetail.model';
import {IshtarContact} from '../../domain/models/company_details_page/ishtar-contact.model';
import {IshtarCountryTranslation} from '../../domain/models/company_details_page/ishtar-country-translation.model';
import {IshtarCountry} from "../../domain/models/company_details_page/ishtar-country.model";

export const getCompanyDetails = createAction(
  '[CompanyDetail] Get company details from a company',
  props<{
    sortedColumn: string;
    sortDirection: string;
    callback?: () => void
  }>()
);
export const getCompanyDetailsResolved = createAction(
  '[CompanyDetail] Get company details from a company resolved',
  props<{ companyDetails: IshtarCompanyDetailExpanded[] }>()
);

export const newCompanyDetail = createAction(
  '[CompanyDetail] create a new companyDetail',
  props<{ newCompanyDetail: IshtarCompanyDetail; callback?: () => void }>()
);
export const newCompanyDetailResolved = createAction(
  '[CompanyDetail] create a new companyDetail resolved',
  props<{ companyDetail: IshtarCompanyDetail }>()
);

export const patchCompanyDetail = createAction(
  '[CompanyDetail] patch a companyDetail',
  props<{ companyDetail: IshtarCompanyDetail; callback?: () => void }>()
);
export const patchCompanyDetailResolved = createAction(
  '[CompanyDetail] patch a companyDetail resolved',
  props<{ companyDetail: IshtarCompanyDetail }>()
);

export const getContacts = createAction(
  '[Contact] Get all contacts',
  props<{
    sortedColumn: string;
    sortDirection: string;
    callback?: () => void
  }>()
);
export const getContactsResolved = createAction(
  '[Contact] Get all contacts resolved',
  props<{ contacts: IshtarContact[] }>()
);

export const removeContact = createAction(
  '[Contact] Remove a contact',
  props<{ IshtarDocsContactIds: string[]; callback?: () => void }>()
);
export const removeContactResolved = createAction(
  '[Contact] Remove a contact resolved',
  props<{ contactIds: string[] }>()
);

export const patchContact = createAction(
  '[Contact] patch a contact',
  props<{ contact: IshtarContact; callback?: () => void }>()
);
export const patchContactResolved = createAction(
  '[Contact] patch a contact resolved',
  props<{ contact: IshtarContact }>()
);

export const newContact = createAction(
  '[Contact] create a new contact',
  props<{ contact: IshtarContact; callback?: () => void }>()
);
export const newContactResolved = createAction(
  '[Contact] create a new contact resolved',
  props<{ contact: IshtarContact }>()
);

export const getCountryTranslations = createAction(
  '[Contact] get all country translations',
  props<{ callback?: () => void }>()
);
export const getCountryTranslationsResolved = createAction(
  '[Contact] get all country translations resolved',
  props<{ translations: IshtarCountryTranslation[] }>()
);

export const getCountries = createAction(
  '[Contact] get all countries',
  props<{ callback?: () => void }>()
);
export const getCountriesResolved = createAction(
  '[Contact] get all countries resolved',
  props<{ countries: IshtarCountry[] }>()
);

export const newCityTranslations = createAction(
  '[Contact] create new city translations',
  props<{ translations: IshtarCityTranslation[]; callback?: () => void }>()
);
export const newCityTranslationsResolved = createAction(
  '[Contact] create new city translations resolved',
  props<{ translations: IshtarCityTranslation[] }>()
);

export const patchCityTranslations = createAction(
  '[Contact] patch city translations',
  props<{ translations: IshtarCityTranslation[]; callback?: () => void }>()
);
export const patchCityTranslationsResolved = createAction(
  '[Contact] patch city translations resolved',
  props<{ translations: IshtarCityTranslation[] }>()
);
