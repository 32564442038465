export class IshtarTagType {
  ishtarDocsTagTypeId!: string;
  name!: string;
  isMultiBlock?: boolean;
  type!: TagTypeFunction;

  constructor(obj: Partial<IshtarTagType>) {
    Object.assign(this, obj);
  }
}

export enum TagTypeFunction {
  Fillable = 'Fillable',
  Hideable = 'Hideable',
  Footnote = 'Footnote',
  None = '',
}
