import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, of, tap } from 'rxjs';
import { IshtarTemplateService } from '../../api/services/ishtarTemplate.service';
import {
  getCompanyDetails,
  getCompanyDetailsResolved,
  getContacts,
  getContactsResolved, getCountries, getCountriesResolved,
  getCountryTranslations,
  getCountryTranslationsResolved,
  newCityTranslations,
  newCityTranslationsResolved,
  newCompanyDetail,
  newCompanyDetailResolved,
  newContact,
  newContactResolved,
  patchCityTranslations,
  patchCityTranslationsResolved,
  patchCompanyDetail,
  patchCompanyDetailResolved,
  patchContact,
  patchContactResolved,
  removeContact,
  removeContactResolved,
} from './company-details.actions';
import {catchError, mergeMap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CompanyDetailsEffects {
  constructor(
    private actions$: Actions,
    private ishtarTemplate: IshtarTemplateService
  ) {}

  getCompanyDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyDetails),
      mergeMap(
        ({
            sortedColumn,
            sortDirection,
            callback,
          }) =>
          this.ishtarTemplate.getCompanyDetails(
            sortedColumn,
            sortDirection
          ).pipe(
            switchMap((data) =>
              of(
                getCompanyDetailsResolved({
                  companyDetails: data
                })
              )
            ),
            tap(() => (callback ? callback() : undefined)),
            catchError((e)=> [])
          )
      )
    )
  );

  newCompanyDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(newCompanyDetail),
      switchMap(({ newCompanyDetail, callback }) =>
        this.ishtarTemplate.newCompanyDetails(newCompanyDetail).pipe(
          switchMap((data) =>
            of(newCompanyDetailResolved({ companyDetail: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchCompanyDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(patchCompanyDetail),
      switchMap(({ companyDetail, callback }) =>
        this.ishtarTemplate.patchCompanyDetails(companyDetail).pipe(
          switchMap((data) =>
            of(patchCompanyDetailResolved({ companyDetail: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getContacts = createEffect(() =>
    this.actions$.pipe(
      ofType(getContacts),
      mergeMap(
        ({
          sortedColumn,
          sortDirection,
          callback,
        }) =>
        this.ishtarTemplate.getContacts(
          sortedColumn,
          sortDirection
        ).pipe(
          switchMap((data) =>
            of(
              getContactsResolved({
                contacts: data
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e)=> [])
        )
      )
    )
  );

  removeContact = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContact),
      switchMap(({ IshtarDocsContactIds, callback }) =>
        this.ishtarTemplate.removeContact(IshtarDocsContactIds).pipe(
          switchMap((data) => of(removeContactResolved({ contactIds: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchContact = createEffect(() =>
    this.actions$.pipe(
      ofType(patchContact),
      switchMap(({ contact, callback }) =>
        this.ishtarTemplate.patchContact(contact).pipe(
          switchMap((data) => of(patchContactResolved({ contact: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  newContact = createEffect(() =>
    this.actions$.pipe(
      ofType(newContact),
      switchMap(({ contact, callback }) =>
        this.ishtarTemplate.newContact(contact).pipe(
          switchMap((data) => of(newContactResolved({ contact: data }))),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getCountryTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountryTranslations),
      switchMap(({ callback }) =>
        this.ishtarTemplate.getCountryTranslations().pipe(
          switchMap((data) =>
            of(getCountryTranslationsResolved({ translations: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  getCountries = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountries),
      switchMap(({ callback }) =>
        this.ishtarTemplate.getCountries().pipe(
          switchMap((data) =>
            of(getCountriesResolved({ countries: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  newCityTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(newCityTranslations),
      switchMap(({ translations, callback }) =>
        this.ishtarTemplate.newCityTranslations(translations).pipe(
          switchMap((data) =>
            of(newCityTranslationsResolved({ translations: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  patchCityTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(patchCityTranslations),
      switchMap(({ translations, callback }) =>
        this.ishtarTemplate.patchCityTranslations(translations).pipe(
          switchMap((data) =>
            of(patchCityTranslationsResolved({ translations: data }))
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );
}
