import {Injectable} from '@angular/core';
import {DocumentFacade} from '../store/document/document.facade';
import {switchMap, tap} from "rxjs";
import {IshtarTemplateService} from "../api/services/ishtarTemplate.service"
import {filter, first} from "rxjs/operators";
import {varlicense$, varlanguages$, vartranslations$} from "../api/data/data.observables";



@Injectable({providedIn: 'root'})
export class AppInitializer {
  constructor(
    private readonly docFacade: DocumentFacade,
    private ishtarTemplateService: IshtarTemplateService,
  ) {
  }

  getLicense() {
    this.ishtarTemplateService.getLicense().subscribe((data) => {
      varlicense$.next(data);
    });
  }

  getLanguages() {
    this.ishtarTemplateService.getLanguages().subscribe((data) => {
      varlanguages$.next(data);
    });
  }

  getTranslations() {
    this.ishtarTemplateService.getTranslations().subscribe((data) => {
      vartranslations$.next(data);
    });
  }


  initialize() {
    this.getLicense();
    return varlicense$
      .asObservable()
      .pipe(
        filter((license) => !!license),
        first(),
        switchMap(() => {
          this.getLanguages();
          this.getTranslations();
          return vartranslations$.pipe(
            filter((t) => !!Object.keys(t).length),
            first()
          );
        })
      );
  }
}
