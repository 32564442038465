import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {
  IshtarBlockSection,
  NewBlockSectionResponse,
} from '../../domain/models/ishtar-block-section.model';
import {
  IshtarCompanyDetail,
  IshtarCompanyDetailExpanded,
  IshtarCityTranslation,
} from '../../domain/models/company_details_page/ishtar-companydetail.model';
import {IshtarContact} from '../../domain/models/company_details_page/ishtar-contact.model';
import {IshtarDocumentBlock} from '../../domain/models/ishtar-document-block.model';
import {IshtarDocument} from '../../domain/models/ishtar-document.model';
import {TranslationResponse} from '../../domain/models/ishtar-translation-response.model';
import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from '../../domain/models/ishtar-translations.model';
import {IshtarCountryTranslation} from '../../domain/models/company_details_page/ishtar-country-translation.model';
import {
  IshtarDocumentBlockTag,
  IshtarDocumentBlockTagExtended,
} from '../../domain/models/tags/ishtar-document-block-tag.model';
import {IshtarTagType} from '../../domain/models/tags/ishtar-tag-type.model';
import {IshtarTag} from '../../domain/models/tags/ishtar-tag.model';
import {MicrosoftAuthenticationService} from 'processdelight-angular-components';
import {UserLicenseInfo} from '../../domain/models/user/ishtar-user-license-info';
import {varlicense$} from "../data/data.observables";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {IshtarCountry} from "../../domain/models/company_details_page/ishtar-country.model";
import {Router} from "@angular/router";
import {Subject} from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class IshtarTemplateService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService,
    private router: Router) {
  }

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  /// LOGIN

  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    ).pipe(catchError((err) => {
      if (err.status === 401){
      this.router.navigate(['/401']);
      return[];

      }
       return[];
    }));
    }


  getLanguages() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarLanguage`
      )
    );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  ///  DOCUMENTS

  getDocument(documentId: string) {
    return this.httpClient.get<IshtarDocument>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocument/${documentId}`
      )
    ).pipe((map((c) => new IshtarDocument(this.camelcaseKeys(c)))));

  }

  getDocuments(
    newPageSize: number,
    sortedColumn: string,
    sortDirection: string,
    filters: [string, any][],
    newPagingCookie?: string
  ) {
    return this.httpClient
      .get<{
        result: IshtarDocument[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarDocsDocument?${
            newPagingCookie ? `pagingCookie=${newPagingCookie}&` : ''
          }doPaging=true&retrieveTotalRecordCount=true&top=${newPageSize}${this.orderByQuery(
            sortedColumn,
            sortDirection
          )}${this.filterQuery(filters)}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map(({result, pagingCookie, totalRecordCount}) => ({
          result: result.map((c) => new IshtarDocument(this.camelcaseKeys(c))),
          pagingCookie,
          totalRecordCount,
        })),
        tap((c) => console.log(c))
      );
  }

  removeDocument(documentId: string) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocument/${documentId}`
      )
    );
  }

  patchDocument(document: IshtarDocument) {
    const updatedDocument = this.capitalizeKeys(document, 'company', 'language', 'modifiedBy', 'createdBy');
    console.log(updatedDocument);
    return this.httpClient.patch<IshtarDocument>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocument/records`
      ),
      [...updatedDocument]
    ).pipe((map((c) => new IshtarDocument(this.camelcaseKeys(c)))));
  }

  /// BLOCK SECTIONS

  //before paging

  getBlockSections(
    newPageSize: number,
    sortedColumn: string,
    sortDirection: string,
    filters: [string, any][],
    newPagingCookie?: string
  ) {
    return this.httpClient
      .get<{
        result: IshtarBlockSection[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsBlockSection?${
            newPagingCookie ? `pagingCookie=${newPagingCookie}&` : ''
          }doPaging=true&retrieveTotalRecordCount=true&top=${newPageSize}${this.orderByQuery(
            sortedColumn,
            sortDirection
          )}${this.filterQuery(filters)}`
        )
      ).pipe(
        map(({result, pagingCookie, totalRecordCount}) => ({
          result: result.map((c) => new IshtarBlockSection(this.camelcaseKeys(c))),
          pagingCookie,
          totalRecordCount,
        }))
      );
  }

  newBlockSections() {
    return this.httpClient.get<NewBlockSectionResponse>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/blocksection/new`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => new NewBlockSectionResponse(this.camelcaseKeys(c)))));
  }

  getBlockSectionTranslations() {
    return this.httpClient.get<TranslationResponse>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/blocksection/translations`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => new TranslationResponse(this.camelcaseKeys(c)))));
  }

  patchBlockSection(blockSection: IshtarBlockSection) {
    return this.httpClient.patch<IshtarBlockSection>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsBlockSection/records`
      ),
      [
        {
          IshtarDocsBlockSectionId: blockSection.ishtarDocsBlockSectionId,
          Shortname: blockSection.shortname,
          ParentItem: blockSection.parentItem,
        },
      ]
    ).pipe((map((c) => new IshtarBlockSection(this.camelcaseKeys(c)))));
  }

  patchHeaderTranslations(items: IshtarBlockSectionHeaderTranslation[]) {
    const updatedHeaderTranslations = items.map((i) => this.capitalizeKeys(i, 'parentItem', 'language', 'createdBy', 'modifiedBy'));

    return this.httpClient.patch<IshtarBlockSectionHeaderTranslation[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsBlockHeaderTranslation/records`
      ),
      updatedHeaderTranslations
    )
      .pipe((map((c) => c.map((c) => new IshtarBlockSectionHeaderTranslation(this.camelcaseKeys(c))))))
      ;
  }

  patchTextTranslations(items: IshtarBlockSectionTextTranslation[]) {
    const updatedTextTranslations = items.map((i) => this.capitalizeKeys(i, 'parentItem', 'language'));
    return this.httpClient.patch<IshtarBlockSectionTextTranslation[]>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/blocksection/savetranslations/${items[0].parentItem?.id}`
      ),
      updatedTextTranslations,
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarBlockSectionTextTranslation(this.camelcaseKeys(c))))));
  }

  /// DOCUMENT BLOCKS

  getDocumentBlocks() {
    return this.httpClient.get<IshtarDocumentBlock[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocumentBlock`
      )
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlock(this.camelcaseKeys(c))))));
  }

  patchDocumentBlock(documentBlocks: IshtarDocumentBlock[]) {
    const updatedDocumentBlocks = documentBlocks.map((i) => this.capitalizeKeys(i, 'document', 'blockSection', 'createdBy', 'modifiedBy'));

    return this.httpClient.patch<IshtarDocumentBlock[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocumentBlock/records`
      ),
      updatedDocumentBlocks,
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlock(this.camelcaseKeys(c))))));
  }

  getNewDocBlockSections(documentId: string) {
    return this.httpClient.get<IshtarDocumentBlock[]>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/documentBlocks/${documentId}`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlock(this.camelcaseKeys(c))))));
  }

  getDocumentBlockTag(documentBlockId: string) {
    return this.httpClient.get<IshtarDocumentBlockTagExtended[]>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/Tags/Generate/${documentBlockId}`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlockTagExtended(this.camelcaseKeys(c))))));
  }

  getDocumentBlockTags() {
    return this.httpClient.get<IshtarDocumentBlockTagExtended[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocumentBlockTag`
      ),
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlockTagExtended(this.camelcaseKeys(c))))));
  }

  patchDocumentBlockTag(docBlockTags: IshtarDocumentBlockTag[]) {
    const updatedDocumentBlockTags = docBlockTags.map((i) => this.capitalizeKeys(i, 'documentBlockSection', 'tag'));

    return this.httpClient.patch<IshtarDocumentBlockTag[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsDocumentBlockTag/records?expand=DocumentBlockSection&select=DocumentBlockSection,*&filter=DocumentBlockSection/IshtarDocsDocumentBlockId eq${docBlockTags[0].documentBlockSection?.id}`
      ),
      updatedDocumentBlockTags,
      {
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    ).pipe((map((c) => c.map((c) => new IshtarDocumentBlockTag(this.camelcaseKeys(c))))));
  }

  // TAGS

  getTags() {
    return this.httpClient.get<IshtarTag[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsTag`
      )
    ).pipe((map((c) => c.map((c) => new IshtarTag(this.camelcaseKeys(c))))));
  }

  getTagTypes() {
    return this.httpClient.get<IshtarTagType[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsTagType`
      )
    ).pipe((map((c) => c.map((c) => new IshtarTagType(this.camelcaseKeys(c))))));
  }

  createTagType(tagType: IshtarTagType) {
    return this.httpClient.post<IshtarTagType[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsTagType/records`
      ),
      [tagType]
    ).pipe((map((c) => c.map((c) => new IshtarTagType(this.camelcaseKeys(c))))));
  }

  patchTagType(tagType: IshtarTagType) {
    const updatedTagType = this.capitalizeKeys(tagType);

    return this.httpClient.patch<[IshtarTagType]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsTagType/records`
      ),
      [updatedTagType]
    ).pipe((map((c) => c.map((c) => new IshtarTagType(this.camelcaseKeys(c))))));
  }

  /// COMPANY DETAILS

  getCompanyDetails(
    sortedColumn: string,
    sortDirection: string,
  ) {
    return this.httpClient.get<IshtarCompanyDetailExpanded[]>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsCompanyDetail?
        ${this.orderByQuery(
          sortedColumn,
          sortDirection
        )}`
      )
    )
      .pipe((map((c) => c.map((c) => new IshtarCompanyDetailExpanded(this.camelcaseKeys(c))))))
      ;
  }

  newCompanyDetails(newCompanyDetail: IshtarCompanyDetail) {
    const updatedCompanyDetail = this.capitalizeKeys(newCompanyDetail, 'country');

    return this.httpClient.post<IshtarCompanyDetail[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsCompanyDetail/records`
      ),
      [updatedCompanyDetail]
    )
      .pipe((map(([c]) => new IshtarCompanyDetail(this.camelcaseKeys(c)))))
      ;
  }

  patchCompanyDetails(companyDetails: IshtarCompanyDetail) {
    const updatedCompanyDetails = this.capitalizeKeys(companyDetails, 'country');

    return this.httpClient.patch<IshtarCompanyDetail[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsCompanyDetail/records`
      ),
      [updatedCompanyDetails]
    )
      .pipe((map(([c]) => new IshtarCompanyDetail(this.camelcaseKeys(c)))))
      ;
  }

  newCityTranslations(cityTranlations: IshtarCityTranslation[]) {
    return this.httpClient.post<IshtarCityTranslation[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsCityTranslation/records`
      ),
      cityTranlations
    )
      .pipe((map((c) => c.map((c) => new IshtarCityTranslation(this.camelcaseKeys(c))))))
      ;
  }

  patchCityTranslations(cityTranlations: IshtarCityTranslation[]) {
    const updatedCityTranslations = cityTranlations.map((i) => this.capitalizeKeys(i, 'parentItem', 'language'));

    return this.httpClient.patch<IshtarCityTranslation[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsCityTranslation/records`
      ),
      updatedCityTranslations
    )
      .pipe((map((c) => c.map((c) => new IshtarCityTranslation(this.camelcaseKeys(c))))))
      ;
  }

  /// CONTACT

  getContacts(
    sortedColumn: string,
    sortDirection: string,
  ) {
    return this.httpClient.get<IshtarContact[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsContact?
        ${this.orderByQuery(
          sortedColumn,
          sortDirection
        )}`
      )
    ).pipe((map((c) => c.map((c) => new IshtarContact(this.camelcaseKeys(c))))));
  }

  removeContact(IshtarDocsContactIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsContact/records`
      ),
      {body: IshtarDocsContactIds}
    );
  }

  patchContact(contact: IshtarContact) {
    const updatedContact = this.capitalizeKeys(contact, 'createdBy', 'modifiedBy');

    return this.httpClient.patch<IshtarContact[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsContact/records`
      ),
      [updatedContact]
    )
      .pipe((map(([c]) => new IshtarContact(this.camelcaseKeys(c)))));
  }

  newContact(contact: IshtarContact) {
    const updatedContact = this.capitalizeKeys(contact);

    return this.httpClient.post<IshtarContact[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarDocsContact/records`
      ),
      [updatedContact]
    )
      .pipe((map(([c]) => new IshtarContact(this.camelcaseKeys(c)))));
  }


// Randoms

  getCountryTranslations() {
    return this.httpClient.get<IshtarCountryTranslation[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarCountryTranslation`
      )
    ).pipe((map((c) => c.map((c) => new IshtarCountryTranslation(this.camelcaseKeys(c))))));
  }

  getCountries() {
    return this.httpClient.get<IshtarCountry[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/IshtarCountry`
      )
    ).pipe((map((c) => c.map((c) => new IshtarCountry(this.camelcaseKeys(c))))));
  }

  private previewObjectUrl?: string;

  getDocumentPreview(documentId: string, lang: string, extraParams = '') {
    return this.httpClient.get<Blob>(
      this.createApiEndpointUrl(
        `ishtar/docs/${this.msal.tenantId}/${varlicense$.value!.dataverseEnvironmentUrl}/${documentId}/documentpreview.pdf?lang=${lang}${extraParams}`
      ),
      {responseType: 'blob' as 'json'}
    ).pipe((map((blob) => {
      if (this.previewObjectUrl) {
        URL.revokeObjectURL(this.previewObjectUrl);
      }
      this.previewObjectUrl = URL.createObjectURL(blob);
      return this.previewObjectUrl;
    })));
  }


  // Helpers


  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  filterQuery(filters: [string, any][]) {
    if (filters.length > 0) {
      let  basicFilterString = '&filter=';

      const variableFilterString =
        filters
          .map((filter) => {
              switch (filter[0]) {
                case 'Company':
                  basicFilterString = '&expand=Company&select=*,Company' + basicFilterString;
                  return `('${filter[1]}' in ${filter[0]}/Name)`;
                case 'ModifiedBy':
                  return `('${filter[1]}' in ModifiedByName)`;
                case 'ModifiedOn':
                  return `(${filter[0]} ge ${filter[1][0].toJSON()} ) and (${filter[0]} le ${filter[1][1].toJSON()} )`;
                default:
                  return `('${filter[1]}' in ${filter[0]})`;
              }
            }
          )
          .join(' and ');
      return basicFilterString + variableFilterString;

    } else {
      return '';
    }
  }


// used for backend to frontend requests
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  // used for frontend to backend requests
  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );
    if (Array.isArray(obj))

      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }

}
