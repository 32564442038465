import { createAction, props } from '@ngrx/store';
import {
  IshtarBlockSection,
  NewBlockSectionResponse,
} from '../../domain/models/ishtar-block-section.model';
import {
  IshtarDocumentBlockTag,
  IshtarDocumentBlockTagExtended,
} from '../../domain/models/tags/ishtar-document-block-tag.model';
import { IshtarDocumentBlock } from '../../domain/models/ishtar-document-block.model';
import { TranslationResponse } from '../../domain/models/ishtar-translation-response.model';
import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from '../../domain/models/ishtar-translations.model';

// export const getBlockSections = createAction(
//   '[BlockSection] Get all blockSections',
//   props<{ callback?: () => void }>()
// );
export const getBlockSections = createAction(
  '[BlockSection] Get all blockSections',
  props<{
    newPageSize: number;
    sortedColumn: string;
    sortDirection: string;
    filters: [string,any][];
    newPagingCookie?: string;
    callback?: () => void;
  }>()
);
export const getBlockSectionsResolved = createAction(
  '[BlockSection] Get all blockSections Resolved',
  props<{
    result: IshtarBlockSection[]
    pagingCookie: string;
    totalRecordCount: number;
    resetPaging?: boolean;
  }>()
);

export const newBlockSection = createAction(
  '[BlockSection] Create a new blockSections',
  props<{ callback?: () => void }>()
);
export const newBlockSectionResolved = createAction(
  '[BlockSection] Create a new blockSections Resolved',
  props<{ blockSectionResponse: NewBlockSectionResponse }>()
);

export const getDocumentBlocks = createAction(
  '[BlockSection] Get all DocumentBlocks',
  props<{ callback?: () => void }>()
);
export const getDocumentBlocksResolved = createAction(
  '[BlockSection] Get all DocumentBlocks Resolved',
  props<{ documentBlocks: IshtarDocumentBlock[] }>()
);

export const getBlockSectionTranslations = createAction(
  '[BlockSection] Get all BlockSection Translations',
  props<{ callback?: () => void }>()
);
export const getBlockSectionTranslationsResolved = createAction(
  '[BlockSection] Get all BlockSection Translations Resolved',
  props<{ response: TranslationResponse }>()
);

export const patchBlockSection = createAction(
  '[BlockSection] Patch one DocumentBlock',
  props<{ BlockSection: IshtarBlockSection; callback?: () => void }>()
);
export const patchBlockSectionResolved = createAction(
  '[BlockSection] Patch one DocumentBlock Resolved',
  props<{ blockSection: IshtarBlockSection }>()
);

export const patchBlockHeaderTranslations = createAction(
  '[BlockHeaderTranslation] Patch Block Translation',
  props<{
    blockHeaderTranslations: IshtarBlockSectionHeaderTranslation[];
    callback?: () => void;
  }>()
);
export const patchBlockHeaderTranslationsResolved = createAction(
  '[BlockHeaderTranslation] Patch Block Translation Resolved',
  props<{
    response: IshtarBlockSectionHeaderTranslation[];
  }>()
);

export const patchBlockTextTranslations = createAction(
  '[BlockTextTranslation] Patch Block Translation',
  props<{
    blockTextTranslations: IshtarBlockSectionTextTranslation[];
    callback?: () => void;
  }>()
);
export const patchBlockTextTranslationsResolved = createAction(
  '[BlockTextTranslation] Patch Block Translation Resolved',
  props<{
    response: IshtarBlockSectionTextTranslation[];
  }>()
);

export const getNewDocBlockSections = createAction(
  '[BlockSection] Get new Document Block Sections',
  props<{ documentId: string; callback?: () => void }>()
);
export const getNewDocBlockSectionsResolved = createAction(
  '[BlockSection] Get new Document Block Sections Resolved',
  props<{
    response: IshtarDocumentBlock[];
  }>()
);

export const getDocBlockTags = createAction(
  '[BlockSection] Get tags for a documentBlock',
  props<{ documentBlockId: string; callback?: () => void }>()
);
export const getDocBlockTagsResolved = createAction(
  '[BlockSection] Get tags for a documentBlock Resolved',
  props<{
    response: IshtarDocumentBlockTagExtended[];
  }>()
);

export const patchDocBlockTags = createAction(
  '[BlockSection] Patch tags for a documentBlock',
  props<{
    documentBlockTags: IshtarDocumentBlockTag[];
    callback?: () => void;
  }>()
);
export const patchDocBlockTagsResolved = createAction(
  '[BlockSection] Patch tags for a documentBlock Resolved',
  props<{
    response: IshtarDocumentBlockTag[];
  }>()
);

export const patchDocumentBlocks = createAction(
  '[DocumentBlocks] Patch multiple document blocks',
  props<{ documentBlocks: IshtarDocumentBlock[]; callback?: () => void }>()
);
export const patchDocumentBlocksResolved = createAction(
  '[DocumentBlocks] Patch multiple document blocks Resolved',
  props<{ response: IshtarDocumentBlock[] }>()
);
