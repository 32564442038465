import { IshtarTagExtended } from './ishtar-tag.model';

export class IshtarDocumentBlockTag {
  ishtarDocsDocumentBlockTagId!: string;
  value?: string;
  tag!: { id: string; name: string };
  documentBlockSection?: { id: string; name: string };

  constructor(
    obj: Partial<IshtarDocumentBlockTagExtended | IshtarDocumentBlockTag>
  ) {
    Object.assign(this, obj);
    if (obj.tag == undefined) return;
    if (obj instanceof IshtarDocumentBlockTagExtended) {
      this.tag = {
        id: obj.tag?.ishtarDocsTagId,
        name: obj.tag.name || '',
      };
    }
  }
}

export class IshtarDocumentBlockTagExtended {
  ishtarDocsDocumentBlockTagId!: string;
  value?: string;
  tag?: IshtarTagExtended;
  documentBlockSection?: { id: string; name: string };

  constructor(
    obj: Partial<IshtarDocumentBlockTag | IshtarDocumentBlockTagExtended>
  ) {
    Object.assign(this, obj);
  }
}
