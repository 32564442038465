import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, switchMap, takeUntil} from 'rxjs';
import {CoreModule} from 'src/app/core/core.module';
import {
  IshtarCompanyDetail,
  IshtarCompanyDetailExpanded
} from 'src/app/core/domain/models/company_details_page/ishtar-companydetail.model';
import {IshtarContact} from 'src/app/core/domain/models/company_details_page/ishtar-contact.model';
import {
  IshtarCountryTranslation
} from 'src/app/core/domain/models/company_details_page/ishtar-country-translation.model';
import {CompanyDetailsFacade} from 'src/app/core/store/company-details/company-details.facade';
import {DocumentFacade} from 'src/app/core/store/document/document.facade';
import {vartranslations$} from "../../core/api/data/data.observables";
import {ColumnDef, DashboardComponent} from "processdelight-angular-components";
import {MatDialog} from "@angular/material/dialog";
import {ContactEditorDialogComponent} from "./contact-editor-dialog/contact-editor-dialog.component";
import {
  CompanyDetailsEditorDialogComponent
} from "./company-details-editor-dialog/company-details-editor-dialog.component";
import {IshtarCountry} from "../../core/domain/models/company_details_page/ishtar-country.model";
import {map} from "rxjs/operators";

export const CONTACTSCOLUMNS = 'ISHTAR-TEMPLATE-CONTACT-DISPLAYEDCOLUMNS';
export const CONTACTSSORTDIRECTION = 'ISHTAR-TEMPLATE-CONTACT-SORTDIRECTION';
export const CONTACTSSORTEDCOLUMN = 'ISHTAR-TEMPLATE-CONTACT-SORTEDCOLUMN';

export const COMPANIESCOLUMNS = 'ISHTAR-TEMPLATE-COMPANY-DISPLAYEDCOLUMNS';
export const COMPANIESSORTDIRECTION = 'ISHTAR-TEMPLATE-COMPANY-SORTDIRECTION';
export const COMPANIESSORTEDCOLUMN = 'ISHTAR-TEMPLATE-COMPANY-SORTEDCOLUMN';

@Component({
  standalone: true,
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
  imports: [CoreModule, DashboardComponent],
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {


  //translations for the user interface
  translations = vartranslations$.value;


  countryTranslations?: IshtarCountryTranslation[];
  countries?:IshtarCountry[];

  destroy$ = new Subject<void>();

  //dashboard contact
  contacts$ = this.companyDetailsFacade.contacts$;

  sortedContactColumn = 'FirstName';
  contactSortDirection: 'asc' | 'desc' | '' = 'asc';
  possibleContactColumns: ColumnDef<IshtarContact>[] = [
    new ColumnDef({
      internalName: 'FirstName',
      displayName: this.translations.firstName,
      sortable: true,
      valueType: 'string',
      valueAccessor: (contact) => contact.firstName,
    }),
    new ColumnDef({
      internalName: 'LastName',
      displayName: this.translations.lastName,
      sortable: true,
      valueType: 'string',
      valueAccessor: (contact) => contact.lastName,
    }),
    new ColumnDef({
      internalName: 'Function',
      displayName: this.translations.function,
      sortable: true,
      valueType: 'string',
      valueAccessor: (contact) => contact.function,
    }),
    new ColumnDef({
      internalName: 'PhoneNr',
      displayName: this.translations.telephoneNumber,
      sortable: true,
      valueType: 'string',
      valueAccessor: (contact) => contact.phoneNr,
    }),
    new ColumnDef({
      internalName: 'Mail',
      displayName: this.translations.email,
      sortable: true,
      valueType: 'string',
      valueAccessor: (contact) => contact.mail,
    })
  ];

  selectedContactsColumn: string[] = [
    'FirstName',
    'LastName',
    'Function',
    'PhoneNr',
    'Mail'
  ]

  //dashboard Company
  companies$ = this.companyDetailsFacade.companyDetails$;

  sortedCompanyColumn = 'FirstName';
  companySortDirection: 'asc' | 'desc' | '' = 'asc';
  possibleCompanyColumns: ColumnDef<IshtarCompanyDetailExpanded>[] = [
    new ColumnDef({
      internalName: 'Name',
      displayName: this.translations.name,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.name,
    }),
    new ColumnDef({
      internalName: 'Street',
      displayName: this.translations.street,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.street,
    }),
    new ColumnDef({
      internalName: 'HouseNumber',
      displayName: this.translations.function,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.houseNumber,
    }),
    new ColumnDef({
      internalName: 'PhoneNr',
      displayName: this.translations.telephoneNumber,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.phoneNr,
    }),
    new ColumnDef({
      internalName: 'EnterpriseNumber',
      displayName: this.translations.enterpriseNumber,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.enterpriceNr,
    }),
    new ColumnDef({
      internalName: 'Mail',
      displayName: this.translations.email,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.mail,
    }),
    new ColumnDef({
      internalName: 'Website',
      displayName: this.translations.website,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.website,
    }),
    new ColumnDef({
      internalName: 'Zipcode',
      displayName: this.translations.zipcode,
      sortable: true,
      valueType: 'string',
      valueAccessor: (company) => company.zipcode,
    })
  ];

  selectedCompanyColumns: string[] = [
    'Name',
    'PhoneNr',
    'Mail',
    'EnterpriseNumber',
    'Website',
    'Zipcode'
  ]

  countryTranslations$ = this.companyDetailsFacade.countryTranslations$;
  countries$ = this.companyDetailsFacade.countries$;


  constructor(
    private companyDetailsFacade: CompanyDetailsFacade,
    public contactEditorDialog: MatDialog,
    public companyEditorDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.contactSortInit();
    this.companySortInit();
    this.countriesInit();


  }



  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  //dashboard contact
  companySortChanged(event: { sortedColumn: string; sortDirection: "" | "asc" | "desc" }) {
    if (event.sortDirection == "") {
      this.sortedCompanyColumn = "CompanyName";
      this.companySortDirection = "asc";
    } else {
      this.sortedCompanyColumn = event.sortedColumn;
      this.companySortDirection = event.sortDirection;
    }
    this.companyDetailsFacade.getCompanyDetails$(
      this.sortedCompanyColumn,
      this.companySortDirection
    )
    localStorage.setItem(COMPANIESSORTDIRECTION, this.companySortDirection);
    localStorage.setItem(COMPANIESSORTEDCOLUMN, this.sortedCompanyColumn);

  }

  companyColumnChanged(event: string[]) {
    localStorage.setItem(COMPANIESCOLUMNS, JSON.stringify(event));
  }

  //dashboard contact

  contactSortChanged(event: { sortedColumn: string; sortDirection: "" | "asc" | "desc" }) {
    if (event.sortDirection == "") {
      this.sortedContactColumn = "FirstName";
      this.contactSortDirection = "asc";
    } else {
      this.sortedContactColumn = event.sortedColumn;
      this.contactSortDirection = event.sortDirection;
    }
    this.companyDetailsFacade.getContacts$(
      this.sortedContactColumn,
      this.contactSortDirection
    )
    localStorage.setItem(CONTACTSSORTDIRECTION, this.contactSortDirection);
    localStorage.setItem(CONTACTSSORTEDCOLUMN, this.sortedContactColumn);


  }

  contactColumnChanged(event: string[]) {
    localStorage.setItem(CONTACTSCOLUMNS, JSON.stringify(event));
  }

  //ADD/EDIT/DELETE COMPANY
  addCompany() {
    const dialogRef = this.companyEditorDialog.open(CompanyDetailsEditorDialogComponent, {
      autoFocus: false,
      data: {
        company: new IshtarCompanyDetailExpanded({
          name: '',
          street: '',
          houseNumber: '',
          zipcode: '',
          phoneNr: '',
          enterpriceNr: '',
          website: '',
          mail: '',
          cityTranslations: []
        }),
        isNewCompany: true,
        countriesInAllLanguages: this.countryTranslations,
        allCountries: this.countries,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.closeCompanyDialog(result);
    });
  }

  editCompany($event: IshtarCompanyDetailExpanded) {
    const dialogRef = this.companyEditorDialog.open(CompanyDetailsEditorDialogComponent, {
      autoFocus: false,
      data: {
        company: $event,
        isNewCompany: false,
        countriesInAllLanguages: this.countryTranslations,
        allCountries: this.countries,
      },
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.closeCompanyDialog(result);
    });
  }

  //ADD/EDIT/DELETE CONTACT

  addContact() {
    const dialogRef = this.contactEditorDialog.open(ContactEditorDialogComponent, {
      autoFocus: false,
      data: {
        contact: new IshtarContact({
          firstName: '',
          lastName: '',
          function: '',
          phoneNr: '',
          mail: '',
        }),
        isNewContact: true,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.closeContactDialog(result);
    });
  }

  editContact(event: IshtarContact) {
    const dialogRef = this.contactEditorDialog.open(ContactEditorDialogComponent, {
      autoFocus: false,
      data: {
        contact: event,
        isNewContact: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.closeContactDialog(result);
    });
  }

  removeContact(contact: IshtarContact) {
    //todo: connect with html and button
    this.companyDetailsFacade.removeContact([contact.ishtarDocsContactId]);
  }

  //PRIVATE

  private contactSortInit() {
    this.contactSortDirection = <'asc' | 'desc' | ''>(
      (localStorage.getItem(CONTACTSSORTDIRECTION) || this.contactSortDirection)
    );
    this.sortedContactColumn = localStorage.getItem(CONTACTSSORTEDCOLUMN) || this.sortedContactColumn;
  }
  private companySortInit() {
    this.companySortDirection = <'asc' | 'desc' | ''>(
      (localStorage.getItem(COMPANIESSORTDIRECTION) || this.companySortDirection)
    );
    this.sortedCompanyColumn = localStorage.getItem(COMPANIESSORTEDCOLUMN) || this.sortedCompanyColumn;
  }

  private closeContactDialog(result: boolean) {
    if (result) {
      //todo: add snackbar to confirm edit/add
    }
  }

  private closeCompanyDialog(result: boolean) {
    if (result) {
      //todo: add snackbar to confirm edit/add
    }
  }

  private countriesInit() {
    this.companyDetailsFacade.getCountryTranslations();
    this.countryTranslations$.subscribe((countries) => {
        this.countryTranslations = countries;
      }
    )
    this.companyDetailsFacade.getCountries();
    this.countries$.subscribe((countries) => {
        this.countries = countries;
      }
    )
  }
}
