import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AppState, NOOP_ACTION } from '../../app.reducer';
import { startLoading, stopLoading } from './loader.actions';

@Injectable({ providedIn: 'root' })
export class LoaderEffects {
  constructor(private actions$: Actions, private _store$: Store<AppState>) {}

  showLoader = createEffect(() =>
    this.actions$.pipe(
      ofType(startLoading),
      mergeMap(({ id, action }) =>
        action
          ? action.pipe(
              switchMap(() => of(stopLoading({ id }))),
              catchError((err) => {
                console.error(err);
                return [stopLoading({ id })]; // TODO: add toaster message
              })
            )
          : of(NOOP_ACTION())
      )
    )
  );
}
