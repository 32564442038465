<div class="company">

<div>

  <h3 class="header">{{translations.companyDetails}}</h3>
  <div class="companycontainer ">

    <ng-container class="h-100" *ngIf="companies$ | async as companies">
      <processdelight-dashboard
        [data]="companies"
        [possibleColumns]="possibleCompanyColumns"
        addButtonLabel="{{translations.addCompany}}"
        [showExpandedDetails]="false"
        (addButtonClicked)="addCompany()"
        [showAddButton]="true"


        (sortChange)="companySortChanged($event)"


        [selectedColumns]="selectedCompanyColumns"
        (columnChange)="companyColumnChanged($event)"
        [sortColumn]="sortedCompanyColumn"
        [sortDirection]="companySortDirection"
        (rowExpanded)="editCompany($event)"
      ></processdelight-dashboard>
    </ng-container>
  </div>

  </div>

  <div>
    <h3 class="header">{{translations.contacts}}</h3>

  <div class="contactcontainer ">


    <ng-container *ngIf="contacts$ | async as contacts">

      <processdelight-dashboard
        class="h-100"
        [data]="contacts"
        [possibleColumns]="possibleContactColumns"
        addButtonLabel="{{translations.addContact}}"
        [showExpandedDetails]="false"
        (addButtonClicked)="addContact()"
        [showAddButton]="true"


        (sortChange)="contactSortChanged($event)"


        [selectedColumns]="selectedContactsColumn"
        (columnChange)="contactColumnChanged($event)"
        [sortColumn]="sortedContactColumn"
        [sortDirection]="contactSortDirection"
        (rowExpanded)="editContact($event)"

      ></processdelight-dashboard>


    </ng-container>
  </div>
  </div>
</div>

