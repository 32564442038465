import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {IshtarContact} from "../../../core/domain/models/company_details_page/ishtar-contact.model";
import {vartranslations$} from "../../../core/api/data/data.observables";
import {NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CompanyDetailsFacade} from "../../../core/store/company-details/company-details.facade";
import {MatButtonModule} from "@angular/material/button";

@Component(
  {
    standalone: true,
    selector: 'app-contact-editor-dialog',
    templateUrl: './contact-editor-dialog.component.html',
    imports: [
      MatDialogModule,
      NgIf,
      MatIconModule,
      MatInputModule,
      ReactiveFormsModule,
      MatButtonModule
    ],
    styleUrls: ['./contact-editor-dialog.component.scss']
  }
)
export class ContactEditorDialogComponent implements OnInit {
  translations = vartranslations$.value;

  isNewContact = false;

  activeContact?: IshtarContact;

  contactForm = new FormGroup({
    FirstName: new FormControl('', [Validators.required]),
    LastName: new FormControl('', [Validators.required]),
    Function: new FormControl('', [Validators.required]),
    PhoneNr: new FormControl('', [
      Validators.required,
      Validators.pattern('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'),
    ]),
    Mail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  constructor(public contactEditorDialogRef: MatDialogRef<ContactEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { contact: IshtarContact, isNewContact: boolean },
              private companyDetailsFacade: CompanyDetailsFacade) {
  }

  ngOnInit(): void {
    this.activeContact = this.data.contact;
    this.isNewContact = this.data.isNewContact;

    this.fillInInitialContactForm(this.activeContact);
  }


  onCloseContactEditorDialog(): void {
    this.contactEditorDialogRef.close();
  }

  saveContact() {
    if (this.contactForm.invalid || this.contactForm.pristine) return;
    const obj = {
      firstName: this.contactForm.value.FirstName || '', // will never be null/undefined but we need to bcs js cries otherwise
      lastName: this.contactForm.value.LastName || '',
      function: this.contactForm.value.Function || '',
      phoneNr: this.contactForm.value.PhoneNr || '',
      mail: this.contactForm.value.Mail || '',
    };
    if (this.activeContact !== undefined){
      const newContact = new IshtarContact({
        ...this.activeContact,
        ...obj,
      }
      );

      if (!this.isNewContact){
      this.companyDetailsFacade.patchContact(newContact);
      }else{
        this.companyDetailsFacade.newContact(newContact);
      }
    }
    else {
      console.log("when does this happen?", obj);
      this.companyDetailsFacade.newContact(new IshtarContact(obj));
      console.log("lol now it does");
    }
  }

  private fillInInitialContactForm(initialContact: IshtarContact) {
    this.contactForm.patchValue({
      FirstName: initialContact.firstName || null,
      LastName: initialContact.lastName || null,
      Function: initialContact.function || null,
      PhoneNr: initialContact.phoneNr || null,
      Mail: initialContact.mail || null,
    });
  }

}
