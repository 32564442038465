import { IshtarTranslation } from '../ishtar-translations.model';

export class IshtarCountryTranslation extends IshtarTranslation {
  ishtarCountryTranslationId!: string;

  constructor(obj: Partial<IshtarCountryTranslation>) {
    super();
    delete (obj as any).name;
    Object.assign(this, obj);
  }
}
