import { ActionReducerMap } from '@ngrx/store';
import { DocumentEffects } from './document/document.effects';
import * as fromDocument from './document/document.reducer';
import * as fromBlockSection from './blockSection/blockSection.reducer';
import * as fromCompanyDetail from './company-details/company-details.reducer';
import * as fromTags from './tags/tags.reducer';
import * as fromShared from '../../shared/store/shared-feature/shared.feature';
import { InjectionToken } from '@angular/core';
import { BlockSectionEffects } from './blockSection/blockSection.effects';
import { CompanyDetailsEffects } from './company-details/company-details.effects';
import { TagEffects } from './tags/tags.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromDocument.featureSlice]: fromDocument.State;
  [fromBlockSection.featureSlice]: fromBlockSection.State;
  [fromCompanyDetail.featureSlice]: fromCompanyDetail.State;
  [fromTags.featureSlice]: fromTags.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromDocument.featureSlice]: fromDocument.Reducer,
      [fromBlockSection.featureSlice]: fromBlockSection.Reducer,
      [fromCompanyDetail.featureSlice]: fromCompanyDetail.Reducer,
      [fromTags.featureSlice]: fromTags.Reducer,
    }),
  }
);
export const effects = [
  DocumentEffects,
  BlockSectionEffects,
  CompanyDetailsEffects,
  TagEffects,
];

export interface AppState {
  [featureKey]: CoreState;
  [fromShared.featureKey]: fromShared.SharedState;
}
