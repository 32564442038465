import { Action, createReducer, on } from '@ngrx/store';
import {
  IshtarCompanyDetail,
  IshtarCompanyDetailExpanded,
} from '../../domain/models/company_details_page/ishtar-companydetail.model';
import { IshtarContact } from '../../domain/models/company_details_page/ishtar-contact.model';
import { IshtarCountryTranslation } from '../../domain/models/company_details_page/ishtar-country-translation.model';

import {
  getCompanyDetailsResolved,
  getContactsResolved,
  getCountriesResolved,
  getCountryTranslationsResolved,
  newCityTranslationsResolved,
  newCompanyDetailResolved,
  newContactResolved,
  patchCityTranslationsResolved,
  patchCompanyDetailResolved,
  patchContactResolved,
  removeContactResolved,
} from './company-details.actions';
import {IshtarCountry} from "../../domain/models/company_details_page/ishtar-country.model";

export const featureSlice = 'companyDetails';

export interface State {
  companyDetails: IshtarCompanyDetailExpanded[];
  newCompanyDetails?: IshtarCompanyDetail;
  contacts: IshtarContact[];
  countryTranslations: IshtarCountryTranslation[];
  countries: IshtarCountry[];
}
const defaultState: State = {
  companyDetails: [],
  contacts: [],
  countryTranslations: [],
  countries: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(getCompanyDetailsResolved, (state, { companyDetails: companyDetail }) => ({
    ...state,
    companyDetails: companyDetail,
  })),
  on(newCompanyDetailResolved, (state, { companyDetail }) => ({
    ...state,
    companyDetails: [... state.companyDetails, new IshtarCompanyDetailExpanded(companyDetail)],
    newCompanyDetails: companyDetail,
  })),
  on(
    patchCompanyDetailResolved,
    (state, { companyDetail }) => ({
      ...state,
      companyDetails: state.companyDetails.map((c) =>
        c.ishtarDocsCompanyDetailId == companyDetail.ishtarDocsCompanyDetailId
          ? new IshtarCompanyDetailExpanded({
              ...companyDetail,
              cityTranslations: c.cityTranslations,
            })
          : c
      ),
    })
  ),
  on(getContactsResolved, (state, { contacts }) => ({
    ...state,
    contacts: contacts,
  })),
  on(removeContactResolved, (state, { contactIds }) => ({
    ...state,
    contacts: state.contacts.filter(
      (c) => !contactIds.filter((id) => id == c.ishtarDocsContactId).length
    ),
  })),
  on(patchContactResolved, (state, { contact }) => ({
    ...state,
    contacts: state.contacts.map((c) =>
      c.ishtarDocsContactId == contact.ishtarDocsContactId
        ? contact
        : c
    ),
  })),
  on(newContactResolved, (state, { contact }) => ({
    ...state,
    contacts: [...state.contacts, contact],
  })),
  on(getCountryTranslationsResolved, (state, { translations }) => ({
    ...state,
    countryTranslations: translations,
  })),
  on(getCountriesResolved, (state, { countries }) => ({
    ...state,
    countries: countries,
  })),
  on(newCityTranslationsResolved, (state, { translations }) => ({
    ...state,
    companyDetails: state.companyDetails.map((cd) => {
      const newtranslations = translations.filter(
        (tr) => tr.parentItem?.id === cd.ishtarDocsCompanyDetailId
      );
      return new IshtarCompanyDetailExpanded({
        ...cd,
        cityTranslations: newtranslations || [],
      });
    }),
  })),
  on(patchCityTranslationsResolved, (state, { translations }) => ({
    ...state,
    companyDetails: state.companyDetails.map((cd) => {
      const newtranslations = translations.filter(
        (tr) => tr.parentItem?.id === cd.ishtarDocsCompanyDetailId
      );
      return new IshtarCompanyDetailExpanded({
        ...cd,
        cityTranslations: newtranslations || [],
      });
    }),
  }))
);
