import Quill from 'quill';

const Inline = Quill.import('blots/inline');
const Embed = Quill.import('blots/embed');

function isEqual(obj1: any, obj2: any): boolean {
  obj1 = Object.values(obj1)[0];
  obj2 = Object.values(obj2)[0];
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
  for (const prop in obj1) {
    if (obj1[prop] != obj2[prop]) return false;
  }
  return true;
}

export class CustomEmbedBlot extends Embed {}
export class CustomBlot extends Inline {
  create(data: any) {
    const node = super.create(data);
    return node;
  }

  optimize(context: { [key: string]: any }) {
    super['optimize'](context);
    const formats = this['formats']();
    if (Object.keys(formats).length === 0) {
      return this['unwrap'](); // unformatted span
    }
    const next = this['next'];
    if (
      next instanceof CustomBlot &&
      next['prev'] === this &&
      isEqual(formats, next['formats']())
    ) {
      next['moveChildren'](this);
      next['remove']();
    }
  }
}

export class SpecialBlot extends CustomBlot {
  static blotName = 'special';
  static tagName = 'special';
  static multiBlock = true;

  static create(data: { title: string }) {
    const node = super['create']();
    node.setAttribute('title', data.title);
    return node;
  }

  static formats(node: HTMLElement) {
    return { title: node.getAttribute('title') };
  }
}

export class FillActionBlot extends CustomBlot {
  static blotName = 'fillable';
  static tagName = 'fillable';
  static multiBlock = true;

  static create(data: { id: string; title: string }) {
    const node = super['create']();

    node.dataset.id = data.id;
    node.title = data.title;

    return node;
  }

  static formats(node: any) {
    return {
      id: node.dataset['id'],
      title: node.getAttribute('title'),
    };
  }
}

export class ShowActionBlot extends CustomBlot {
  static blotName = 'hideable';
  static tagName = 'hideable';
  static multiBlock = true;

  static create(data: { id: string; title: string }) {
    const node = super['create'](data);

    node.dataset.id = data.id;
    node.setAttribute('title', data.title);

    return node;
  }

  static formats(node: any) {
    return {
      id: node.dataset['id'],
      title: node.getAttribute('title'),
    };
  }
}

// export class PriceBlot extends CustomEmbedBlot {
//   static blotName = 'price';
//   static tagName = 'price';
//   static multiBlock = false;
//
//   static create(data: { id: string; title: string }) {
//     const node = super['create']();
//     node.dataset.id = data.id;
//     node.title = data.title.replace(/(\r\n|\n|\r)/gm, '');
//
//     const denotationChar = document.createElement('span');
//     denotationChar.innerHTML = `[${data.title}]`;
//     node.appendChild(denotationChar);
//
//     return node;
//   }
//
//   static value(node: any) {
//     return {
//       id: node.dataset.id,
//       title: node.getAttribute('title'),
//     };
//   }
//
//   static formats(node: any) {
//     return {
//       id: node.dataset.id,
//       title: node.getAttribute('title'),
//     };
//   }
// }

// export class FootNoteBlot extends CustomEmbedBlot {
//   static blotName = 'footnote';
//   static tagName = 'footnote';
//   static multiBlock = false;
//
//   static create(data: { id: string; title: string }) {
//     const node = super['create'](data);
//     node.dataset.id = data.id;
//     //data.text doesn't exist (yet)
//     // node.title = data.text.replace(/(\r\n|\n|\r)/gm, '');
//
//     // const denotationChar = document.createElement('span');
//     // denotationChar.innerHTML = '[Footnote]';
//     // node.appendChild(denotationChar);
//
//     node.setAttribute('title', data.title)
//
//     return node;
//   }
//
//   static value(node: any) {
//     return {
//       id: node.dataset.id,
//       text: node.getAttribute('title'),
//     };
//   }
//   static formats(node: any) {
//     return {
//       id: node.dataset.id,
//       title: node.getAttribute('title'),
//     };
//   }
// }
export class FootnoteBlot extends CustomBlot {
  static blotName = 'footnote';
  static tagName = 'footnote';
  static multiBlock = true;

  static create(data: { id: string; title: string }) {
    const node = super['create'](data);

    node.dataset.id = data.id;
    node.setAttribute('title', data.title);

    return node;
  }

  static formats(node: any) {
    return {
      id: node.dataset['id'],
      title: node.getAttribute('title'),
    };
  }
}
