import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {CompanyDetailsFacade} from "../../../core/store/company-details/company-details.facade";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {ContactEditorDialogComponent} from "../contact-editor-dialog/contact-editor-dialog.component";
import { IshtarCompanyDetail,
  IshtarCompanyDetailExpanded
} from "../../../core/domain/models/company_details_page/ishtar-companydetail.model";
import {vartranslations$} from "../../../core/api/data/data.observables";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgForOf, NgIf} from "@angular/common";
import {
  IshtarCountryTranslation
} from "../../../core/domain/models/company_details_page/ishtar-country-translation.model";
import {Subject} from "rxjs";
import {DocumentFacade} from "../../../core/store/document/document.facade";
import {IshtarCountry} from "../../../core/domain/models/company_details_page/ishtar-country.model";

@Component(
  {
    standalone: true,
    selector: 'app-company-details-editor-dialog',
    templateUrl: './company-details-editor-dialog.component.html',
    imports: [
      MatInputModule,
      MatOptionModule,
      NgxMatSelectSearchModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatDialogModule,
      MatIconModule,
      MatButtonModule,
      NgIf,
      NgForOf
    ],
    styleUrls: ['./company-details-editor-dialog.component.scss']
  }
)
export class CompanyDetailsEditorDialogComponent implements OnInit, OnDestroy {
  translations = vartranslations$.value;

  activeCompanyDetail?: IshtarCompanyDetailExpanded;
  isNewCompany = false;
  selectedCountry: IshtarCountry | undefined = undefined;

  companyDetailsForm = new FormGroup({
    CompanyName: new FormControl(''),
    CompanyStreet: new FormControl(''),
    CompanyHouseNr: new FormControl(),
    CompanyZipcode: new FormControl(),
    CompanyCityEN: new FormControl(''),
    CompanyCityNL: new FormControl(''),
    CompanyCityFR: new FormControl(''),
    CompanyCountry: new FormControl('', [Validators.required]),
    CompanyCountryNL: new FormControl(''),
    CompanyCountryFR: new FormControl(''),
    CompanyPhoneNr: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
      ),
    ]),
    CompanyEnterpriseNr: new FormControl(''),
    CompanyWebsite: new FormControl(''),
    CompanyMail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  countriesInAllLanguages?: IshtarCountryTranslation[];
  allCountries?: IshtarCountry[];

  destroy$ = new Subject<void>();
  countriesToChooseFrom: { Language: { id: string; name: string }; translation: string }[] = [];


  constructor(public companyDetailEditorDialogRef: MatDialogRef<ContactEditorDialogComponent>,
              private docFacade: DocumentFacade,
              @Inject(MAT_DIALOG_DATA) public data: {
                company: IshtarCompanyDetailExpanded,
                isNewCompany: boolean,
                countriesInAllLanguages: IshtarCountryTranslation[],
                allCountries: IshtarCountry[]
              },
              private companyDetailsFacade: CompanyDetailsFacade) {
  }

  ngOnInit(): void {
    this.isNewCompany = this.data.isNewCompany;
    this.activeCompanyDetail = this.data.company;

    this.companyCountryInit();

    this.companyDetailsForm.get('CompanyCountry')?.valueChanges.subscribe(
      (e) => this.onFormCountryChange(e || '')
    );

    this.fillInInitialCompanyDetailForm(this.activeCompanyDetail);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onFormCountryChange(country: string) {
    this.selectedCountry = this.allCountries?.find((c) => c.countryName == country) || new IshtarCountry({ ishtarCountryId: "", countryName: "" });


    const countryNL = this.countriesInAllLanguages?.find((c) =>
      c.parentItem?.name == country && c.language.name == 'Dutch');


    const countryFR = this.countriesInAllLanguages?.find((c) =>
      c.parentItem?.name == country && c.language.name == 'French');


    this.companyDetailsForm.patchValue({
      CompanyCountryNL: countryNL?.translation,
      CompanyCountryFR: countryFR?.translation,
    });
  }

  onSaveCompanyDetails() {

    if (this.companyDetailsForm.invalid || this.companyDetailsForm.pristine) return;
    let country: {id: string, name: string } | undefined;

    if (this.selectedCountry?.countryName && this.selectedCountry?.ishtarCountryId) {
      country = { id: this.selectedCountry.ishtarCountryId, name: this.selectedCountry.countryName };
    }else{
      country = undefined
    }


    const newCompanyDetails: IshtarCompanyDetail = new IshtarCompanyDetail(

    {
      // ...this.activeCompanyDetail!,
      ishtarDocsCompanyDetailId: this.activeCompanyDetail?.ishtarDocsCompanyDetailId || undefined,
      name: this.companyDetailsForm.value.CompanyName!,
      street: this.companyDetailsForm.value.CompanyStreet || undefined,
      houseNumber: this.companyDetailsForm.value.CompanyHouseNr || undefined,
      zipcode: this.companyDetailsForm.value.CompanyZipcode || undefined,
      country: country,
      phoneNr: this.companyDetailsForm.value.CompanyPhoneNr!,
      enterpriceNr: this.companyDetailsForm.value.CompanyEnterpriseNr || undefined,
      website: this.companyDetailsForm.value.CompanyWebsite || undefined,
      mail: this.companyDetailsForm.value.CompanyMail!,
      // cityTranslations: [
      //   {
      //     language: Languages.getLanguages()[0],
      //     translation: this.companyDetailsForm.value.CompanyCityEN || "",
      //   },
      //   {
      //     language: Languages.getLanguages()[1],
      //     translation: this.companyDetailsForm.value.CompanyCityNL || "",
      //   },
      //   {
      //     language: Languages.getLanguages()[2],
      //     translation: this.companyDetailsForm.value.CompanyCityFR || "",
      //   },
      // ],
        }
    )
        if (this.isNewCompany){
          this.companyDetailsFacade.newCompanyDetail(newCompanyDetails);
        }else{
          this.companyDetailsFacade.patchCompanyDetails(newCompanyDetails);
        }

    }






  onCloseCompanyDialog() {
    this.companyDetailEditorDialogRef.close();
  }


  private fillInInitialCompanyDetailForm(activeCompanyDetail: IshtarCompanyDetailExpanded) {

    if (activeCompanyDetail.country) {
      this.selectedCountry = new IshtarCountry(
        {
          ishtarCountryId: activeCompanyDetail.country.id,
          countryName: activeCompanyDetail.country.name,
        });
    }

    let cityTranslations: string[]
    if (activeCompanyDetail.cityTranslations.length == 0) {
      cityTranslations = ["", "", ""];
    } else {
      cityTranslations = activeCompanyDetail.cityTranslations.map((ct) => ct.translation);
    }

    this.companyDetailsForm.patchValue({
      CompanyName: activeCompanyDetail.name || undefined,
      CompanyStreet: activeCompanyDetail.street || undefined,
      CompanyHouseNr: activeCompanyDetail.houseNumber || undefined,
      CompanyZipcode: activeCompanyDetail.zipcode || undefined,
      CompanyCityEN: cityTranslations[0] || undefined,
      CompanyCityNL: cityTranslations[1] || undefined,
      CompanyCityFR: cityTranslations[2] || undefined,
      CompanyCountry: activeCompanyDetail.country?.name || undefined,
      CompanyCountryNL: activeCompanyDetail.country?.name || undefined,
      CompanyCountryFR: activeCompanyDetail.country?.name || undefined,
      CompanyPhoneNr: activeCompanyDetail.phoneNr || undefined,
      CompanyEnterpriseNr: activeCompanyDetail.enterpriceNr || undefined,
      CompanyWebsite: activeCompanyDetail.website || undefined,
      CompanyMail: activeCompanyDetail.mail || undefined,
    });
  }

  private companyCountryInit() {
    this.companyDetailsFacade.getCountryTranslations();
    this.countriesInAllLanguages = this.data.countriesInAllLanguages;
    this.allCountries = [...this.data.allCountries].sort((a, b) => a.countryName.localeCompare(b.countryName));

    this.countriesToChooseFrom = this.countriesInAllLanguages.filter((c) => c.language.name == "English")
      .map((c) => ({
        translation: c.translation,
        Language: c.language,
      }));
  }
}



