import { IshtarBlockSection } from '../../domain/models/ishtar-block-section.model';
import {
  getBlockSectionsResolved,
  getBlockSectionTranslationsResolved,
  getDocBlockTagsResolved,
  getDocumentBlocksResolved,
  getNewDocBlockSectionsResolved,
  newBlockSectionResolved,
  patchBlockHeaderTranslationsResolved,
  patchBlockSectionResolved,
  patchBlockTextTranslationsResolved,
  patchDocBlockTagsResolved,
  patchDocumentBlocksResolved,
} from './blockSection.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { IshtarDocumentBlockExtended } from '../../domain/models/ishtar-document-block.model';
import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from '../../domain/models/ishtar-translations.model';
import { IshtarDocumentBlockTagExtended } from '../../domain/models/tags/ishtar-document-block-tag.model';
import { IshtarTag } from '../../domain/models/tags/ishtar-tag.model';
import {AppState} from "../../../shared/store/app.reducer";

export const featureSlice = 'blockSection';

export interface State {
  blockSections: IshtarBlockSection[];
  documentBlocks: IshtarDocumentBlockExtended[];
  blockSectionHeaderTranslations: IshtarBlockSectionHeaderTranslation[];
  blockSectionTextTranslations: IshtarBlockSectionTextTranslation[];
  activeTags: IshtarDocumentBlockTagExtended[];
  tags: IshtarTag[];
  pagingCookie: string;
  totalRecordCount: number;
}

const defaultState: State = {
  blockSections: [],
  documentBlocks: [],
  blockSectionHeaderTranslations: [],
  blockSectionTextTranslations: [],
  activeTags: [],
  tags: [],
  pagingCookie: '',
  totalRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(getBlockSectionsResolved, (state,  { result, pagingCookie, totalRecordCount, resetPaging }) => ({
    ...state,
    blockSections: resetPaging ? [...result] : [...state.blockSections, ...result],
    pagingCookie,
    totalRecordCount,
  })),
  on(newBlockSectionResolved, (state, { blockSectionResponse }) => ({
    ...state,
    blockSections: [...state.blockSections, new IshtarBlockSection({
      ...blockSectionResponse.blockSections[0],
      headerTranslations: blockSectionResponse.headerTranslations,
      textTranslations: blockSectionResponse.textTranslations,
    }) ],
  })),
  on(getDocumentBlocksResolved, (state, { documentBlocks }) => ({
    ...state,
    documentBlocks: documentBlocks.map(
      (d) => new IshtarDocumentBlockExtended(d)
    ),
  })),
  on(getBlockSectionTranslationsResolved, (state, { response }) => ({
    ...state,
    blockSections: state.blockSections?.map((blockSection) => {
      return new IshtarBlockSection({
        ...blockSection,
        headerTranslations: response.headerTranslations.filter(
          (header) =>
            header.parentItem?.id === blockSection.ishtarDocsBlockSectionId
        ),
        textTranslations: response.textTranslations.filter(
          (text) =>
            text.parentItem?.id === blockSection. ishtarDocsBlockSectionId
        ),
      });
    }),
  })),
  on(patchBlockSectionResolved, (state, { blockSection }) => ({
    ...state,
    blockSection: state.blockSections.map((block) =>
      block.ishtarDocsBlockSectionId == blockSection.ishtarDocsBlockSectionId
        ? blockSection
        : block
    ),
  })),
  on(patchBlockHeaderTranslationsResolved, (state, { response }) => ({
    ...state,
    blockSections: state.blockSections.map((blockSection) => {
      const newheaders = blockSection.headerTranslations?.map((oldheader) => {
        const newheader = response.find(
          (hdr) =>
            hdr.ishtarDocsBlockHeaderTranslationId ===
            oldheader.ishtarDocsBlockHeaderTranslationId
        );
        return newheader ? newheader : oldheader;
      });
      return new IshtarBlockSection({
        ...blockSection,
        headerTranslations: newheaders,
      });
    }),
  })),
  on(patchBlockTextTranslationsResolved, (state, { response }) => ({
    ...state,
    blockSections: state.blockSections.map((blockSection) => {
      const newtexts = blockSection.textTranslations?.map((oldtext) => {
        const newtext = response.find(
          (hdr) =>
            hdr.ishtarDocsBlockTextTranslationId ===
            oldtext.ishtarDocsBlockTextTranslationId
        );
        return newtext ? newtext : oldtext;
      });
      return new IshtarBlockSection({
        ...blockSection,
        textTranslations: newtexts,
      });
    }),
  })),
  on(getNewDocBlockSectionsResolved, (state, { response }) => ({
    ...state,
    documentBlocks: response.map((d) => new IshtarDocumentBlockExtended(d)),
  })),
  on(getDocBlockTagsResolved, (state, { response }) => ({
    ...state,
    documentBlocks: state.documentBlocks.map((block) => {
      const thisTags = response.filter(
        (t) => t.documentBlockSection?.id === block.ishtarDocsDocumentBlockId
      );
      if (thisTags.length == 0) return block;
      const returnblock = new IshtarDocumentBlockExtended(block);
      returnblock.tags = response.map((t) => t);
      return returnblock;
    }),
  })),
  on(patchDocBlockTagsResolved, (state, { response }) => ({
    ...state,
    documentBlocks: state.documentBlocks.map((block) => {
      return new IshtarDocumentBlockExtended({
        ...block,
        tags: block.tags.map((t) => {
          const newtag = response.find(
            (r) =>
              r.ishtarDocsDocumentBlockTagId == t.ishtarDocsDocumentBlockTagId
          );
          return new IshtarDocumentBlockTagExtended({
            ...t,
            value: newtag?.value || t.value,
          });
        }),
      });
    }),
  })),
  on(patchDocumentBlocksResolved, (state, { response }) => ({
    ...state,
    documentBlocks: state.documentBlocks.map((block) => {
      const patched = response.find(
        (b) => b.ishtarDocsDocumentBlockId === block.ishtarDocsDocumentBlockId
      );
      return patched == undefined
        ? block
        : new IshtarDocumentBlockExtended(patched);
    }),
  }))
);
export const blockSectionState = (state: AppState) => state.coreFeature.document;
