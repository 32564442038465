<div class="dialogContainer">

  <h1 mat-dialog-title *ngIf="isNewContact; else addContact">{{translations.newContact}}</h1>
  <ng-template #addContact><h1 mat-dialog-title>{{translations.contactEditor}}</h1></ng-template>

  <mat-icon class="closeButtonIcon" (click)="onCloseContactEditorDialog()">close</mat-icon>

  <mat-dialog-content class="mat-typography">
    <form
      [formGroup]="contactForm"
      (ngSubmit)="saveContact()"
      class="d-flex flex-column"
    >
      <div class="contacteditor">
        <mat-form-field class="contactInput">
          <mat-label>{{translations.firstName}}</mat-label>
          <input
            matInput
            required
            placeholder=""
            formControlName="FirstName"
          />
        </mat-form-field>
        <mat-form-field class="contactInput">
          <mat-label>{{translations.lastName}}</mat-label>
          <input
            matInput
            required
            placeholder=""
            formControlName="LastName"
          />
        </mat-form-field>
        <mat-form-field class="contactInput">
          <mat-label>{{translations.function}}</mat-label>
          <input
            matInput
            required
            placeholder=""
            formControlName="Function"
          />
        </mat-form-field>
        <mat-form-field class="contactInput">
          <mat-label>{{translations.telephoneNumber}}</mat-label>
          <input
            matInput
            required
            placeholder=""
            formControlName="PhoneNr"
            type="tel"
          />
        </mat-form-field>
        <mat-form-field class="contactInput">
          <mat-label>{{translations.email}}</mat-label>
          <input matInput required placeholder="" formControlName="Mail"/>
        </mat-form-field>
      </div>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary"
            [mat-dialog-close]="true"
            cdkFocusInitial
            [disabled]="!contactForm.valid"
            (click)="saveContact()"
    >
      <mat-icon>save</mat-icon>
      {{translations.save}}
    </button>
  </mat-dialog-actions>

</div>
