import { createSelector } from '@ngrx/store';
import * as fromCore from '../core.feature';
import { AppState } from '../core.feature';

const blockSectionState = (state: AppState) => state.coreFeature.blockSection;
const getBlockSections = createSelector(
  blockSectionState,
  (state) => state.blockSections
);

const getDocumentBlocks = createSelector(
  blockSectionState,
  (state) => state.documentBlocks
);

const getActiveTags = createSelector(
  blockSectionState,
  (state) => state.activeTags
);

const getTotalRecordCount = createSelector(blockSectionState, (state) => state.totalRecordCount);

const getPagingCookie = createSelector(blockSectionState, (state) => state.pagingCookie);

export const blockSectionSelectors = {
  getBlockSections,
  getDocumentBlocks,
  getActiveTags,
  getTotalRecordCount,
  getPagingCookie,
};
