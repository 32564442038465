export class IshtarDocument {
  ishtarDocsDocumentId!: string;
  title!: string;
  company?: { id: string; name: string };
  language?: { id: string; name: string };
  date?: string;
  createdBy!: { id: string; name: string };
  createdOn!: string;
  modifiedBy?: { id: string; name: string };
  modifiedOn?: string;

  constructor(obj: Partial<IshtarDocument>) {
    delete (obj as any).name;
    delete (obj as any).id;
    Object.assign(this, obj);
  }
}
