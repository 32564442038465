import { Action, createReducer, on } from '@ngrx/store';
import {
  IshtarDocumentBlockTag,
  IshtarDocumentBlockTagExtended,
} from '../../domain/models/tags/ishtar-document-block-tag.model';
import { IshtarTagType } from '../../domain/models/tags/ishtar-tag-type.model';
import { IshtarTag } from '../../domain/models/tags/ishtar-tag.model';
import { getBlockSectionsResolved } from '../blockSection/blockSection.actions';
import {
  createTagTypeResolved,
  getAllDocBlockTagsResolved,
  getTagsResolved,
  getTagTypesResolved,
  patchTagTypeResolved,
} from './tags.actions';

export const featureSlice = 'tags';

export interface State {
  tagTypes: IshtarTagType[];
  tags: IshtarTag[];
  documentBlockTags: IshtarDocumentBlockTagExtended[];
}

const defaultState: State = {
  tagTypes: [],
  tags: [],
  documentBlockTags: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const initialState: State = defaultState;
export const userReducer = createReducer(
  initialState,
  on(getTagsResolved, (state, { response }) => ({
    ...state,
    tags: response,
  })),
  on(getTagTypesResolved, (state, { response }) => ({
    ...state,
    tagTypes: response,
  })),
  on(getAllDocBlockTagsResolved, (state, { response }) => ({
    ...state,
    documentBlockTags: response,
  })),
  on(createTagTypeResolved, (state, { response }) => ({
    ...state,
    tagTypes: [...state.tagTypes, ...response],
  })),
  on(patchTagTypeResolved, (state, { response }) => ({
    ...state,
    tagTypes: state.tagTypes.map((t) => {
      const type = response.find(
        (tag) => tag.ishtarDocsTagTypeId == t.ishtarDocsTagTypeId
      );
      return type ? type : t;
    }),
  }))
);
