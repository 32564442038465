import { Component } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {Router} from "@angular/router";

@Component({
  standalone: true,
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.html',
  imports: [
    CoreModule
  ],
  styleUrls: ['./unauthorized-access.scss']
})
export class UnauthorizedAccessComponent {
  constructor(private router: Router) {
  }

  returnToHome() {
    console.log("button clicked");
    console.log(this.router.config)
    const queryParams = new URLSearchParams(location.search);
    this.router.navigate([''], {queryParams: {tenantId: queryParams.get('tenantId')}});
  }
}
