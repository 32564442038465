export class IshtarTranslation {
  translation!: string;
  //name!: string;
  parentItem?: { id: string; name: string };
  language!: { id: string; name: string };
}

export class IshtarBlockSectionHeaderTranslation extends IshtarTranslation {
  ishtarDocsBlockHeaderTranslationId!: string;

  constructor(obj: Partial<IshtarBlockSectionHeaderTranslation>) {
    super();
    delete (obj as any).name;
    delete (obj as any).id;
    Object.assign(this, obj);
  }
}
export class IshtarBlockSectionTextTranslation extends IshtarTranslation {
  ishtarDocsBlockTextTranslationId!: string;

  constructor(obj: Partial<IshtarBlockSectionTextTranslation>) {
    super();
    delete (obj as any).name;
    Object.assign(this, obj);
  }
}
