<div class="lay-over" *ngIf="isLoading$ | async">
  <div class="wrapper">
    <div class="spinner">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <hr />
      <span class="loader-text" *ngIf="loaderText$ | async as loaderText">{{
        loaderText
      }}</span>
    </div>
  </div>
</div>
