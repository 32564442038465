export class IshtarContact {
  ishtarDocsContactId!: string;
  firstName!: string;
  lastName?: string;
  function?: string;
  phoneNr?: string;
  mail?: string;

  constructor(obj: Partial<IshtarContact>) {
    delete (obj as any).id;
    delete (obj as any).name;
    Object.assign(this, obj);
  }
}
