import {Component, ElementRef, OnDestroy, OnInit, ViewChild}
  from '@angular/core';
import {IshtarBlockSection} from 'src/app/core/domain/models/ishtar-block-section.model';
import {IshtarTranslation} from 'src/app/core/domain/models/ishtar-translations.model';
import {BlockSectionFacade} from '../../core/store/blockSection/blockSection.facade';
import {Language, Languages} from '../../language.component';
import {CoreModule} from 'src/app/core/core.module';
import {TextEditorComponent} from '../components/text-editor/text-editor.component';
import {BehaviorSubject, combineLatest, Subject, takeUntil} from 'rxjs';
import {TagEditorComponent} from '../components/tag-editor/tag-editor.component';
import {ColumnDef, DashboardComponent} from "processdelight-angular-components";
import {MatDialog} from "@angular/material/dialog";
import {TextBlockDialogComponent} from "./text-block-dialog/text-block-dialog.component";
import {first, map} from "rxjs/operators";
import {vartranslations$} from "../../core/api/data/data.observables";
import {TagFacade} from "../../core/store/tags/tags.facade";

export const TEXTBLOCKSPAGESIZE = 'ISHTAR-TEMPLATE-TEXTBLOCK-PAGESIZE';
export const TEXTBLOCKSCOLUMNS = 'ISHTAR-TEMPLATE-TEXTBLOCK-DISPLAYEDCOLUMNS';
export const TEXTBLOCKSSORTDIRECTION = 'ISHTAR-TEMPLATE-TEXTBLOCK-SORTDIRECTION';
export const TEXTBLOCKSSORTEDCOLUMN = 'ISHTAR-TEMPLATE-TEXTBLOCK-SORTEDCOLUMN';


@Component({
  standalone: true,
  selector: 'app-text-blocks',
  templateUrl: './text-blocks.component.html',
  styleUrls: ['./text-blocks.component.scss'],
  imports: [CoreModule, TextEditorComponent, TagEditorComponent, DashboardComponent],
})
export class TextBlocksComponent implements OnInit, OnDestroy {
  //translations for the user interface
  translations = vartranslations$.value;

  @ViewChild('activeTagContainer', {read: ElementRef})
  activeTagContainer!: ElementRef;

  supportedLanguages: Language[] = Languages.getLanguages();

  //DASHBOARD

  sortedColumn = 'Shortname';
  sortDirection: 'asc' | 'desc' | '' = 'asc';
  filters: [string, any][] = [];
  destroy$ = new Subject<void>();

  possibleColumns: ColumnDef<IshtarBlockSection>[] = [
    new ColumnDef({
      internalName: 'Shortname',
      displayName: this.translations.name,
      sortable: true,
      valueType: 'string',
      valueAccessor: (bs) => bs.shortname,
    }),

  ]
  selectedColumns: string[] = [
    'Shortname',
  ];

  //paging
  totalRecordCount = 0;
  pageSize = 10;
  page$ = new BehaviorSubject<number>(0);
  lastLoadedPage = 0;
  pagedTextBlocks$ = combineLatest([
    this.blockSectionFacade.blockSections$,
    this.page$.asObservable(),
  ]).pipe(
    map(([blockSections, page]) =>
      blockSections.filter(
        (_, index) =>
          index >= page * this.pageSize && index < (page + 1) * this.pageSize
      )
    )
  );



  constructor(private blockSectionFacade: BlockSectionFacade, public textBlockDialog: MatDialog, private tagFacade: TagFacade) {
  }

  ngOnInit(): void {
    //current page
    this.pageSize = Number(localStorage.getItem(TEXTBLOCKSPAGESIZE) || '10');
    this.textBlockColumnsInitialization();
    this.textBlockSortInitialization();
    this.textBlockPagingInitialization();

    this.tagFacade.getTags();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Premade backend function to create blocksection and translations
  newBlockSection() {
    this.blockSectionFacade.newBlockSection();
  }

  // Get translation with specific language from localstorage
  getTranslation(translations: IshtarTranslation[] | undefined, languageId: string) {
    if (translations) {
      const translationItem = translations.find(
        (translation) => translation.language.id === languageId
      );
      if (translationItem) {
        return translationItem.translation;
      }
    }
    return 'No Translation Found';
  }

  filterChanged(event: [string, any][]) {
    if (this.isValidFilter(event)) {
      this.filters = event;
      this.blockSectionFacade
        .getBlockSections$(
          this.pageSize,
          this.sortedColumn,
          this.sortDirection,
          this.filters
        )
        .subscribe(() => {
          this.page$.next(0);
        });

    }
  }


  pageChanged(event: { pagesize: number; page: number }) {
    if (event.pagesize != this.pageSize) {
      this.page$.next(0);
      this.pageSize = event.pagesize;
      localStorage.setItem(TEXTBLOCKSPAGESIZE, event.pagesize.toString());
      this.blockSectionFacade
        .getBlockSections$(
          this.pageSize,
          this.sortedColumn,
          this.sortDirection,
          this.filters
        )
        .subscribe();
      return;
    }

    if (event.page > this.lastLoadedPage) {
      this.page$.next(event.page);
      this.blockSectionFacade.pagingCookie$
        .pipe(first())
        .subscribe((pagingCookie) => {
          this.blockSectionFacade
            .getBlockSections$(
              this.pageSize,
              this.sortedColumn,
              this.sortDirection,
              this.filters,
              pagingCookie
            )
            .subscribe();
        });
    } else {
      this.page$.next(event.page);
    }
  }

  columnChanged(event: string[]) {
    localStorage.setItem(TEXTBLOCKSCOLUMNS, JSON.stringify(event));
  }

  sortChanged(event: { sortedColumn: string; sortDirection: "" | "asc" | "desc" }) {
    if (event.sortDirection == "") {
      this.sortedColumn = "Shortname";
      this.sortDirection = "asc";
    } else {
      this.sortedColumn = event.sortedColumn;
      this.sortDirection = event.sortDirection;
    }
    this.blockSectionFacade
      .getBlockSections$(
        this.pageSize,
        this.sortedColumn,
        this.sortDirection,
        this.filters
      )
      .subscribe(() => {
        this.page$.next(0);
      });
    localStorage.setItem(TEXTBLOCKSSORTDIRECTION, this.sortDirection);
    localStorage.setItem(TEXTBLOCKSSORTEDCOLUMN, this.sortedColumn);

  }

  openDialog(event: IshtarBlockSection) {
    const dialogRef = this.textBlockDialog.open(TextBlockDialogComponent, {
      height: '75%',
      width: 'auto',
      autoFocus: false,
      data: {
        blockSection: event,

      },
      disableClose: true,

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        //todo: add snack bar for success add/edit
      }

      // result is true if save button was clicked
      // result is '' if cancel button was clicked
    });
  }

  //extra functions for dynamic columns

  createPossibleLanguageColumns(): ColumnDef<IshtarBlockSection>[] {
    return this.supportedLanguages.map((language) => (
      new ColumnDef({
        internalName: `${language.code}`,
        displayName: language.viewValue,
        sortable: false,
        valueType: 'string',
        valueAccessor: (bs) => {
          const paragraph = document.createElement('p');
          paragraph.innerHTML = this.getTranslation(bs.headerTranslations, language.id);
          return paragraph.textContent;
        },
        valueHtml: true,
      })));
  }

  getSelectedColumns(): string[] {
    return this.selectedColumns;
  }

  getPossibleColumns(): ColumnDef<IshtarBlockSection>[] {
    return this.possibleColumns;
  }

  //initialization functions

  private textBlockPagingInitialization() {
    this.blockSectionFacade.totalRecordCount$.subscribe(
      (totalRecordCount) => (this.totalRecordCount = totalRecordCount)
    );

    this.page$.pipe(takeUntil(this.destroy$)).subscribe((page) => {
      this.lastLoadedPage = page;
    });
  }

  private textBlockSortInitialization() {
    this.sortDirection = <'asc' | 'desc' | ''>(
      (localStorage.getItem(TEXTBLOCKSSORTDIRECTION) || this.sortDirection)
    );
    this.sortedColumn = localStorage.getItem(TEXTBLOCKSSORTEDCOLUMN) || this.sortedColumn;
  }

  private textBlockColumnsInitialization() {
    //columns, if columns in localstorage, use those, otherwise add possible languages
    if (localStorage.getItem(TEXTBLOCKSCOLUMNS) === null) {

      // possible languages columns added to selected columns
      this.selectedColumns.push(...this.supportedLanguages.map((lang) => lang.code));
    }
    const columnsString = localStorage.getItem(TEXTBLOCKSCOLUMNS);
    this.selectedColumns = columnsString
      ? JSON.parse(columnsString)
      : this.selectedColumns;

    // possible languages added to possible columns
    this.possibleColumns.push(...this.createPossibleLanguageColumns());
  }

  // FILTER FUNCTIONS

  private isValidFilter(event: [string, any][]) {
    if (event.length == 0) {
      return true;
    }
    for (let i = 0; i < event.length; i++) {
      if (event[i][1] == null || event[i][1] == undefined || event[i][1] == '') {
        return false;
      }
    }
    return true;
  }

  // HTML HELPER FUNCTIONS
  getBlotName(blot: any) {
    return blot[0];
  }

  getBlotData(blot: any) {
    return blot[1];
  }

  sortTable(column: number) {
    column = column + 1;
    let table, rows, switching, i, x, y, shouldSwitch;
    // eslint-disable-next-line prefer-const
    table = document.getElementById('blockTable');
    if (table == undefined) return;
    switching = true;
    while (switching) {
      switching = false;
      rows = table.getElementsByTagName('tr');
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('td')[column];
        y = rows[i + 1].getElementsByTagName('td')[column];
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode?.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }
}


