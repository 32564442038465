import {createSelector} from '@ngrx/store';
import * as fromCore from '../core.feature';
import {AppState} from '../core.feature';

const documentState = (state: AppState) => state.coreFeature.document;
const getActiveDocument = createSelector(
  documentState,
  (state) => state.activeDoc
);

const getDocuments = createSelector(documentState, (state) => state.documents);

const getDocumentById = createSelector(
  documentState,
  (state) => (id: string) =>
    state.documents.find((doc) => doc.ishtarDocsDocumentId == id)
);

const getTotalRecordCount = createSelector(documentState, (state) => state.totalRecordCount);

const getPagingCookie = createSelector(documentState, (state) => state.pagingCookie);

export const documentSelectors = {
  getActiveDocument,
  getDocuments,
  getDocumentById,
  getTotalRecordCount,
  getPagingCookie,
};
