import { IshtarBlockSection } from './ishtar-block-section.model';
import {
  IshtarBlockSectionHeaderTranslation,
  IshtarBlockSectionTextTranslation,
} from './ishtar-translations.model';

export class TranslationResponse {
  blockSections!: IshtarBlockSection[];
  headerTranslations!: IshtarBlockSectionHeaderTranslation[];
  textTranslations!: IshtarBlockSectionTextTranslation[];

  constructor(obj: TranslationResponse) {
    Object.assign(this, obj);
  }
}
