import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ContentChange, QuillEditorComponent} from 'ngx-quill';
import {Subject} from 'rxjs';
import {CoreModule} from 'src/app/core/core.module';
import {Language, Languages} from 'src/app/language.component';
import Quill from 'quill';
import 'quill';
import { FillActionBlot, FootnoteBlot, SpecialBlot, ShowActionBlot} from './blots';
import {vartranslations$} from "../../../core/api/data/data.observables";
import {IshtarBlockSection} from "../../../core/domain/models/ishtar-block-section.model";
import {IshtarBlockSectionTextTranslation} from "../../../core/domain/models/ishtar-translations.model";
import {BlockSectionFacade} from "../../../core/store/blockSection/blockSection.facade";

Quill.register(SpecialBlot);
Quill.register(FootnoteBlot);
Quill.register(FillActionBlot);
Quill.register(ShowActionBlot);

@Component({
  standalone: true,
  selector: 'app-text-editor',
  styleUrls: ['./text-editor.component.scss'],
  templateUrl: './text-editor.component.html',
  imports: [CoreModule],
})
export class TextEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() blockSection?: IshtarBlockSection;
  @Input() supportedLanguages: Language[] = [];

  @Output() languageChange = new EventEmitter<void>();
  @Output() editorCreated = new EventEmitter<QuillEditorComponent>();

  //translations for the user interface
  translations = vartranslations$.value;

  activeBlockSection?: IshtarBlockSection;

  //text part
  textRichEditor = '';
  changedText = "";
  // contains the text for each language when initialized in ngOnInit
  textLocalSaves: IshtarBlockSectionTextTranslation[] = [];

  currentTextLanguage = Languages.getLanguages()[0];

  destroy$ = new Subject<void>();
  quillModules = {};

  @ViewChild('editor') editor!: QuillEditorComponent;
  @ViewChild('editor', {read: ElementRef}) editorRef!: ElementRef;

  constructor(private blockSectionFacade: BlockSectionFacade) {
  }


  ngOnInit(): void {
    this.activeBlockSection = this.blockSection!;
    this.textLocalSaves = this.activeBlockSection.textTranslations?.map((text: IshtarBlockSectionTextTranslation) =>
      new IshtarBlockSectionTextTranslation({...text})) || [];

    this.loadTextInEditor();
  }

  ngAfterViewInit(): void {
    this.editorCreated.emit(this.editor);
  }

//Text part
  onTextLanguageChange(id: string) {
    this.saveTextRichEditorToLocal();
    console.log('onTextLanguageChange', this.currentTextLanguage);
    this.currentTextLanguage = this.supportedLanguages.find((lang) => lang.id === id) || this.supportedLanguages[0];
    console.log('onTextLanguageChange', this.currentTextLanguage);

    //reload the text in the editor
    this.loadTextInEditor();

    setTimeout(() => {
      this.languageChange.emit();
    }, 100);
  }

  saveText() {
    if (this.activeBlockSection) {
      this.saveTextRichEditorToLocal();
      this.blockSectionFacade.patchBlockTextTranslations(this.textLocalSaves);
      console.log('saved text', this.textLocalSaves);
    }
  }

  saveTextRichEditorToLocal() {
    console.log('saveTextRichEditorToLocal', this.editor);
    if (this.changedText != "") {

      this.textLocalSaves = this.textLocalSaves.map((localsave) =>
        localsave.language.id == this.currentTextLanguage.id
          ? new IshtarBlockSectionTextTranslation({
            ...localsave,
            translation: this.changedText,
          })
          : localsave
      );
      this.changedText = "";
    }
  }

  private loadTextInEditor() {
    this.textRichEditor = this.textLocalSaves.find((text: IshtarBlockSectionTextTranslation) =>
      text.language.id == this.currentTextLanguage.id)?.translation || 'No translation found';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  contentChanged(event: ContentChange) {
    this.changedText = event.html!;
    console.log('contentChanged', this.changedText);
  }
}
