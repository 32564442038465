import { vartranslations$} from "./core/api/data/data.observables";

export class Languages {
  public static getLanguages() {
    //temp fix until i can get it in state
    const list: Language[] = [
      {
        viewValue: vartranslations$.value.english,
        id: '14dee576-b3e8-ec11-bb3c-000d3a443fb6',
        code: 'en',
      },
      {
        viewValue: vartranslations$.value.dutch,
        id: '15dee576-b3e8-ec11-bb3c-000d3a443fb6',
        code: 'nl',
      },
      {
        viewValue: vartranslations$.value.french,
        id: '16dee576-b3e8-ec11-bb3c-000d3a443fb6',
        code: 'fr',
      },
    ];
    return list;
  }
}

export interface Language {
  id: string;
  code: string;
  viewValue: string;
}
