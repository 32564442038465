import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../core/core.module';
import { LoaderComponent } from './components/loader/loader.component';
import * as fromShared from './store/shared-feature/shared.feature';
import { SearchFilterPipe } from './pipe/search-filter.pipe.pipe';
@NgModule({
  declarations: [
    LoaderComponent,
  ],
  imports: [
    CoreModule,
    MatSidenavModule,
    StoreModule.forFeature(fromShared.featureKey, fromShared.reducers),
    EffectsModule.forFeature(fromShared.effects),
    MatSelectModule,
    SearchFilterPipe,
  ],
  exports: [
    LoaderComponent,
  ],
})
export class SharedModule {}
