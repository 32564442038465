<app-loader></app-loader>
<ng-container
  *ngIf="{
    orgLogo: orgLogo$ | async,
    userName: userName$ | async,
    userPhoto: userPhoto$ | async,
    navColor: navColor$ | async,
    navContrast: navContrast$ | async
  } as data"
  >
  <processdelight-general-layout
    [appName]="title"
    [appLogo]="appLogo"
    [actions]="actions"
    [orgLogo]="data.orgLogo ?? undefined"
    [userName]="data.userName ?? undefined"
    [userPhoto]="data.userPhoto ?? undefined"
    [navColor]="data.navColor?? '#124464'"
    [navContrast]="data.navContrast ?? '#fff' "
  >
    <router-outlet></router-outlet>
  </processdelight-general-layout>
</ng-container>

