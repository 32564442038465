<div class="dialogContainer">

  <h1 mat-dialog-title *ngIf="isNewCompany; else addContact">{{translations.newCompany}}</h1>
  <ng-template #addContact><h1 mat-dialog-title>{{translations.editCompanyDetails}}</h1></ng-template>

  <mat-icon class="closeButtonIcon" (click)="onCloseCompanyDialog()">close</mat-icon>

<mat-dialog-content>


  <form
    class="companydetails"
    [formGroup]="companyDetailsForm"
    (ngSubmit)="onSaveCompanyDetails()"
  >
    <p class="picturelabel">{{translations.companyLogo}}</p>
    <div class="companypicturecontainer">
      <div class="companypicture">
        <img
          class="companyimg"
          src="			https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png"
        />
      </div>
    </div>

    <mat-form-field class="companyinput">
      <mat-label>{{translations.companyName}}</mat-label>
      <input
        matInput
        placeholder="{{translations.name}}"
        required
        formControlName="CompanyName"
      />
    </mat-form-field>
    <mat-form-field class="companyinput">
      <mat-label>{{translations.street}}</mat-label>
      <input matInput placeholder="" formControlName="CompanyStreet"/>
    </mat-form-field>
    <div class="housezip">
      <mat-form-field class="house">
        <mat-label>{{translations.houseNumber}}</mat-label>
        <input
          matInput
          placeholder=""
          type="number"
          formControlName="CompanyHouseNr"
        />
      </mat-form-field>
      <mat-form-field class="zip">
        <mat-label>{{translations.zipcode}}</mat-label>
        <input
          matInput
          placeholder="****"
          type="number"
          formControlName="CompanyZipcode"
        />
      </mat-form-field>
    </div>
    <p class="tripleOptionLabel">{{translations.city}}</p>
    <div class="tripleOption">
      <mat-form-field class="nl">
        <mat-label>EN</mat-label>
        <input matInput placeholder="" formControlName="CompanyCityEN"/>
      </mat-form-field>
      <mat-form-field class="en">
        <mat-label>NL</mat-label>
        <input matInput placeholder="" formControlName="CompanyCityNL"/>
      </mat-form-field>
      <mat-form-field class="fr">
        <mat-label>FR</mat-label>
        <input matInput placeholder="" formControlName="CompanyCityFR"/>
      </mat-form-field>
    </div>
    <p class="tripleOptionLabel">{{translations.country}}</p>
    <div class="tripleOption">
      <mat-form-field>
        <mat-label>EN</mat-label>
        <mat-select placeholder="{{translations.country}}"
                formControlName="CompanyCountry">
          <mat-option *ngFor="let option of allCountries" [value]="option.countryName">
            {{ option.countryName }}</mat-option>

        </mat-select>
      </mat-form-field>


      <mat-form-field class="en mat-focused">
        <mat-label>NL</mat-label>
        <input
          matInput
          readonly
          placeholder=""
          formControlName="CompanyCountryNL"
        />
      </mat-form-field>
      <mat-form-field class="fr mat-focused">
        <mat-label>FR</mat-label>
        <input
          matInput
          readonly
          placeholder=""
          formControlName="CompanyCountryFR"
        />
      </mat-form-field>
    </div>

    <div class="phoneenterprice">
      <mat-form-field class="phone">
        <mat-label>{{translations.globalPhoneNumber}}</mat-label>
        <input matInput placeholder="+" formControlName="CompanyPhoneNr"/>
      </mat-form-field>
      <mat-form-field class="enterprice">
        <mat-label>{{translations.enterpriseNumber}}</mat-label>
        <input
          matInput
          placeholder="BE ****"
          formControlName="CompanyEnterpriseNr"
        />
      </mat-form-field>
    </div>

    <mat-form-field class="companyinput">
      <mat-label>{{translations.website}}</mat-label>
      <input matInput placeholder="www." formControlName="CompanyWebsite"/>
    </mat-form-field>
    <mat-form-field class="companyinput">
      <mat-label>{{translations.email}}</mat-label>
      <input matInput placeholder="" required formControlName="CompanyMail"/>
    </mat-form-field>


  </form>

</mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary"
            [mat-dialog-close]="true"
            cdkFocusInitial
            [disabled]="!companyDetailsForm.valid"
            (click)="onSaveCompanyDetails()"
    >
      <mat-icon>save</mat-icon>
      {{translations.save}}
    </button>
  </mat-dialog-actions>

</div>
