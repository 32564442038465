import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';

export const startLoading = createAction(
  '[Loader] Start loading',
  props<{ id: string; text: string; action?: Observable<any> }>()
);
export const stopLoading = createAction(
  '[Loader] Stop loading',
  props<{ id: string }>()
);
