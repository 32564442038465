import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { companyDetailSelectors } from './company_details.selectors';
import { AppState } from '../core.feature';
import {
  getCompanyDetails,
  getContacts,
  newContact,
  patchContact,
  removeContact,
  newCompanyDetail,
  patchCompanyDetail,
  getCountryTranslations,
  newCityTranslations,
  patchCityTranslations, getCountries,
} from './company-details.actions';
import { IshtarContact } from '../../domain/models/company_details_page/ishtar-contact.model';

import {
  IshtarCompanyDetail,
  IshtarCityTranslation,
} from '../../domain/models/company_details_page/ishtar-companydetail.model';
import {Subject} from "rxjs";
import {LoaderFacade} from "../../../shared/store/shared-feature/loader/loader.facade";

@Injectable({
  providedIn: 'root',
})
export class CompanyDetailsFacade {
  companyDetails$ = this._store.pipe(
    select(companyDetailSelectors.getCompanyDetails)
  );

  newCompanyDetail$ = this._store.pipe(
    select(companyDetailSelectors.getNewCompanyDetail)
  );

  contacts$ = this._store.pipe(select(companyDetailSelectors.getContacts));
  countryTranslations$ = this._store.pipe(
    select(companyDetailSelectors.getCountryTranslations)
  );
  countries$ = this._store.pipe(select(companyDetailSelectors.getCountries));

  constructor(private _store: Store<AppState>,
              private loaderFacade: LoaderFacade
              ) {}

  // getCompanyDetails(callback?: () => void) {
  //   this._store.dispatch(getCompanyDetails({ callback }));
  // }
  getCompanyDetails$(sortedColumn: string, sortDirection: string) {
    const id = this.loaderFacade.startLoading("Loading company details...");
    const subject = new Subject<void>();
    this._store.dispatch(getCompanyDetails({
      sortedColumn, sortDirection,
      callback: () => {
      this.loaderFacade.stopLoading(id);
      subject.next();
      subject.complete();
    } }));
    return subject.asObservable();
  }

  newCompanyDetail(
    NewCompanyDetail: IshtarCompanyDetail,
    callback?: () => void
  ) {
    this._store.dispatch(
      newCompanyDetail({ newCompanyDetail: NewCompanyDetail, callback })
    );
  }

  patchCompanyDetails(
    companyDetail: IshtarCompanyDetail,
    callback?: () => void
  ) {
    this._store.dispatch(patchCompanyDetail({ companyDetail, callback }));
  }

  // getContacts(callback?: () => void) {
  //   this._store.dispatch(getContacts({ callback }));
  // }
  getContacts$(sortedColumn: string, sortDirection: string) {
    const id = this.loaderFacade.startLoading("Loading contacts...");
    const subject = new Subject<void>();
    this._store.dispatch(getContacts({
      sortedColumn, sortDirection,
      callback: () => {
      this.loaderFacade.stopLoading(id);
      subject.next();
      subject.complete();
    } }));
    return subject.asObservable();

  }

  removeContact(IshtarDocsContactIds: string[], callback?: () => void) {
    this._store.dispatch(removeContact({ IshtarDocsContactIds, callback }));
  }

  patchContact(contact: IshtarContact, callback?: () => void) {
    this._store.dispatch(patchContact({ contact, callback }));
  }

  newContact(contact: IshtarContact, callback?: () => void) {
    this._store.dispatch(newContact({ contact, callback }));
  }

  getCountryTranslations(callback?: () => void) {
    this._store.dispatch(getCountryTranslations({ callback }));
  }

  getCountries(callback?: () => void) {
    this._store.dispatch(getCountries({ callback }));
  }

  newCityTranslations(
    translations: IshtarCityTranslation[],
    callback?: () => void
  ) {
    this._store.dispatch(newCityTranslations({ translations, callback }));
  }

  patchCityTranslations(
    translations: IshtarCityTranslation[],
    callback?: () => void
  ) {
    this._store.dispatch(patchCityTranslations({ translations, callback }));
  }
}
