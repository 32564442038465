import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  standalone: true,
})
export class SearchFilterPipe implements PipeTransform {
  /*transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }*/
  transform(items: any[], searchText: string): any[] {
    //No items? return empty array.
    if (!items) {
      return [];
    }
    //no searchtext? return all items
    if (!searchText) {
      return items;
    }
    //change searchtext to lowercase
    searchText = searchText.toLocaleLowerCase();
    //filter over items to see if the title matches the searchtext (all in lowercase)
    return items.filter((it) => {
      return it.name.toLocaleLowerCase().includes(searchText);
    });
  }
}
